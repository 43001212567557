<template>
    <modalComp :idmodal="'previewpatpdf'" :show-header="true" :show-footer="true" :size="'modal-lg'">
        <template v-slot:header>
            <h5 class="modal-title">Patient Report</h5>
           <!-- <div>
            Report Header <select v-model="show_header" class="form-select" @change="fetchPdfAgain(show_header)">
            <option :value="false">No</option>
            <option :value="true">Yes</option></select>
           </div> -->
        </template>
        <template v-slot:body>
            <div>
            <SpinnerDataLoader :classname="'text-primary'"  v-if="getPdfLoader"></SpinnerDataLoader>
          
            <iframe :src="getPdfUrl" width="100%" style="height: calc(100vh - 250px);"
              frameborder="0"></iframe>
          </div>
        </template>
        <template v-slot:footer>
            <div class="col-10 m-0 p-0">
                <!-- <button type="button" class="btn btn-danger  btn-sm  m-2 p-2 " data-bs-dismiss="modal">
                    Cancel
                </button> -->
            </div>
            <div class="col-2 m-0 p-0">
                <button type="button" class="btn btn-danger  btn-sm  m-2 p-2 " data-bs-dismiss="modal">
                    Cancel
                </button>
            </div>
            <!-- <div class="col-6 m-0 p-0">
                <button @click="cancel_wellness()" data-bs-dismiss="modal"  type="button" class="btn btn-primary btn-lg w-100">
                    Print
                </button>
            </div> -->
        </template>
    </modalComp>
</template>

<script setup>
import {ref} from "vue";
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import modalComp from '@/components/common/modal'
import { storeToRefs } from 'pinia'
// import {useManageUser} from '@/core/modules/auth/user'
import {useStoreAgent} from "@/core/modules/agent/agent";
// const {getUser} =  storeToRefs(useManageUser())
const {getPdfUrl,getPdfLoader} =  storeToRefs(useStoreAgent())

const agentStore=useStoreAgent()
const show_header=ref(false)

const props = defineProps({
    bill_no: String,
    // package_name: String,
    // wellness_tab: String
})
// async function fetchPdfAgain(trxn){
//     await agentStore.FetchAgentPatientPdf({
//         testList:trxn.tests,
//         reportHeader:1,
//         bill_no:trxn.bill_no,
//         branch:1

//     })
// }
</script>