import { defineStore } from "pinia";
import axios from "axios";

export const useCalendar = defineStore('calendar', {
    state: () => ({
        errors: null,
        months: [
            {
                m: 'Jan',
                ind: 1,
            },
            {
                m: 'Feb',
                ind: 2,
            },
            {
                m: 'Mar',
                ind: 3,
            },
            {
                m: 'Apr',
                ind: 4,
            },
            {
                m: 'May',
                ind: 5,
            },
            {
                m: 'Jun',
                ind: 6,
            },
            {
                m: 'Jul',
                ind: 7,
            },
            {
                m: 'Aug',
                ind: 8,
            },
            {
                m: 'Sep',
                ind: 9,
            },
            {
                m: 'Oct',
                ind: 10,
            },
            {
                m: 'Nov',
                ind: 11,
            },
            {
                m: 'Dec',
                ind: 12,
            },
        ],
    }),
    getters : {
        getMonths: (state) => {
            return state.months
        }
    },
    persist: true,
})