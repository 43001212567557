// import Router from 'vue-router'
import { createRouter, createWebHistory } from "vue-router";
import landingPage from "@/pages/landing";
import authLoginPage from "@/pages/auth/login";
import authSignUp from "@/pages/auth/signup";
import authPasswordResetPage from "@/pages/auth/resetpassword";
import authOtpPage from "@/pages/auth/otp2";
import authTerms from "@/pages/auth/terms";
import authPrivacy from "@/pages/auth/privacy";
import authRecoverPassword from "@/pages/auth/recoverpassword";
import authSelectAccount from "@/pages/manage/selectaccount";
import componentSideNav_Nav from "@/components/sidenav/nav";
import pageDashboard from "@/pages/dashboard";
import pageManageProfile from "@/pages/manage/profile";
import authChangePassword from "@/pages/manage/changepassword"
import selfMyAppointment from "@/pages/self/myappointment"
import selfPrescriptions from "@/pages/self/prescription"
import selfInvestigation from "@/pages/self/investigationreport"
import selfVitals from "@/pages/self/vitals"
import selfPurchaseHistory from "@/pages/self/purchasehistory"
import selfWellness from "@/pages/self/wellness"
import pagePage from "@/pages/page";
import pageErrorPageNotFound from "@/pages/errors/pagenotfound";
import pageDownloadApp from "@/pages/downloadapp";
import pageContactUs from "@/pages/contact-us";
import pageWellnessPackage from "@/pages/wellnesspackage";
import pageAppointmentDoctor from "@/pages/departmentDetail";
import pageAppointment from '@/pages/appointment'
import pageOpdAppointment from '@/pages/pageOpdAppointment'
import pageDoctorSchedule from "@/pages/doctorSchedule"
import pageSuccessAppointment from "@/pages/successappointment"
import pageDisclaimerAndPrivacy from "@/pages/disclaimerandprivacy"
import pagePaymentResponse from "@/pages/paymentresponse";
import pageDoctorWithSpecialities from "@/pages/pageDoctorWithSpecialities"
import pageDocSlotAppointment from "@/pages/pageDocSlotAppointment"
import pageImmegrationFormAppointment from "@/pages/pageImmegrationFormAppointment"
import pageDashboardAgent from "@/pages/agent/dashboard";
import pageTransactionDetailAgent from "@/pages/agent/transactionDetail";
import pagePatientReportAgent from "@/pages/agent/patientReport";
import pageNewsAgent from "@/pages/agent/newsAgent";

const routes = [
  {
    name: "page.download.app",
    component: pageDownloadApp,
    path: "/download-app",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.landing",
    component: landingPage,
    path: "/",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.login",
    component: authLoginPage,
    path: "/agent/login",
    meta: {
      requiresAuth: false,
    },
  },
 
  {
    name: "page.auth.resetpassword",
    component: authPasswordResetPage,
    path: "/agent/forgot-password",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.privacy",
    component: authPrivacy,
    path: "/privacy",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.terms",
    component: authTerms,
    path: "/terms",
    meta: {
      requiresAuth: false,
    },
  },

  {
    name: "page.auth.recover.password",
    component: authRecoverPassword,
    path: "/agent/recover/password",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.appointment",
    component: pageAppointment,
    path: "/appointment",
    meta: {
      requiresAuth: false,
    },
  },
  

  {
    component: componentSideNav_Nav,
    path: "/",
    children: [
      // {
      //   name: "page.dashboard",
      //   component: pageDashboard,
      //   path: "/agent/dashboard",
      //   meta: {
      //     requiresAuth: true,
      //     title: "Dashboard",
      //   },
      // },

   

      {
        name: "page.manage.profile",
        component: pageManageProfile,
        path: "/agent/profile/me",
        meta: {
          requiresAuth: true,
          title: "My Account",
          desc: "View your profile",
        },
      },
      {
        name: "page.manage.changepassword",
        component: authChangePassword,
        path: "/agent/profile/change-password",
        meta: {
          requiresAuth: true,
          title: "Change Password",
        },
      },
     
     
    ],
  },

  
 
 
  

  {
    name: "page.auth.login.agent",
    component: authLoginPage,
    path: "/agent/login",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.agent.dashboard",
    component: pageDashboardAgent,
    path: "/agent/dashboard",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.agent.transactiondetail",
    component: pageTransactionDetailAgent,
    path: "/agent/transcation/detail",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.agent.patientreport",
    component: pagePatientReportAgent,
    path: "/agent/patient/report",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.agent.agentnews",
    component: pageNewsAgent,
    path: "/agent/news/list",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: pageErrorPageNotFound,
    meta: {
      requiresAuth: false,
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
