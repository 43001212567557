<template>
    <div class="full-load" v-if="getLoaderStatus">
    
        <h1 class="full-load-h1">Please wait while the payment is being processed</h1>
    
        <SpinnerDataLoader class="full-load-spinner" :classname="'text-primary'"></SpinnerDataLoader>
    
    </div>
    <div class="" v-if="getErrors || paymentError">
    
        <div class=" full-load-h1 ">
    
            <h1 class=" text-danger text-center">Failed</h1>
    
            <div class="text-center">
    
                <a class="  btn btn-danger text-white" type="danger" variant="danger" @click="handleRePayaHandler">Try again</a>
    
            </div>
    
        </div>  
    </div>
</template>

<script setup>
import { onMounted, watch, computed, ref } from 'vue'
import { storeToRefs } from "pinia";
import { usePayment } from '@/core/modules/payment'
import router from "@/router/routes";
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import { useRoute } from 'vue-router';
import "core-js/stable/atob";
import { jwtDecode } from "jwt-decode";
const loadPage = true;
const pdfsrc = ref("")
const store = usePayment()
// store.getIsPaymentSuccess
const route = useRoute();
const paymentError = ref(null);
const { getLoaderStatus, getErrors } = storeToRefs(usePayment())
onMounted(async () => {
    const queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let data=urlParams.get("data")
    if(data !=null){
    data=data.replace('==','')

    const decoded = jwtDecode(data,{ header: true });
    decoded.payment_gateway_name="Esewa"
    decoded.amount=decoded.total_amount
    decoded.transaction_id=decoded.transaction_code
    decoded.purchase_order_id=decoded.transaction_uuid
    decoded.status='Completed'
    decoded.payment_type= "WELLNESS"
    decoded.payment_from="Web",
    

    // const schedule_id = window.localStorage.getItem('schedule_id');
    // if(route.query.pidx == "" || route.query.txnId == ""){
    //     paymentError.value = true;
    // }
    // else {
    //     var body = {
    //     schedule_id: schedule_id,
    //     pidx: route.query.pidx,
    //     txnId: route.query.txnId,
    //     amount: route.query.amount,
    //     mobile: route.query.mobile,
    //     purchase_order_id: route.query.purchase_order_id,
    //     purchase_order_name: route.query.purchase_order_name,
    //     transaction_id: route.query.transaction_id
        
    // }
    await store.paymentResponse(decoded)
    }
    else{
    console.log(queryString)
    let payload={
        payment_gateway_name:"Khalti",
        "payment_type": "WELLNESS",
        payment_from:'Web',
     pidx:urlParams.get("pidx"),
     status:urlParams.get("status"),
     transaction_id:urlParams.get("tidx"),
     tidx:urlParams.get("tidx"),
     amount:urlParams.get("amount"),
     total_amount:urlParams.get("total_amount"),
     mobile:urlParams.get("mobile"),
     purchase_order_id:urlParams.get("purchase_order_id")
    }
    await store.paymentResponse(payload)
}
    
    // }

})

const handleRePayaHandler = () => {
    const opd_id = localStorage.getItem("opd_id")
    router.push({ path: `appointment/doctor/${opd_id}` });
}
// watch(() => getIsPaymentError.value, (n) =>{
//     if(n){

//     const sMasterBillId= localStorage.getItem("sMasterBillId")
//                               router.push({ path: `checkout/${sMasterBillId}` });              
//     }
// } );
// function openFile(f) {
//     // return `https://api.meraki.dolphin.com.np${f}`
//     return `http://10.2.3.139:8001${f}`
// }
// watch(() => paymentResponsePdf.value, (newVal) => {
//     if (newVal) {
//         pdfsrc.value = openFile(newVal);
//     }
// });

</script>
<style scoped>
.full-load-h1 {
    position: absolute;
    top: 41%;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
}
.full-load {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  left: 0;
  color: white;
  width: 100%;
  height: 100%;
  background: #0000007d;
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  text-align: center;
}
</style>