<template>
    <modalComp :idmodal="'choosepayment'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Choose Payment</h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <div class="strongpassword">Please choose a payment option
            </div>
            <div class="mt-4">
                <img style="max-width: 100px; cursor: pointer; " :class="selectedPayment == true ? 'active-pay' : ''"  @click="selectPayment" src="@/assets/images/khalti.svg" alt="">
            </div>
        </template>
        <template v-slot:footer>
            <div class="col-6 m-0 p-0"></div>
            <div class="col-6 m-0 p-0">

                <button type="button"  class="btn border-top btn-lg w-100 btn-primary" @click="proceedPayment" data-bs-dismiss="modal">Proceed To Pay</button>
            </div>
            <!-- <div class="col-6 m-0 p-0">
                <button type="button" data-bs-dismiss="modal" class="btn btn-primary btn-lg w-100" >
                    Pay</button>
            </div> -->
        </template>
    </modalComp>
</template>

<script setup>
    import { Modal } from 'bootstrap'
import modalComp from '@/components/common/modal'
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { usePayment } from "@/core/modules/payment"
import router from "@/router/routes";
import { useRoute } from 'vue-router'
import { toast } from "vue3-toastify";
const managePaymentStore = usePayment()
const route = useRoute()
const selectedPayment = ref(true)
const props = defineProps({
})

function proceedPayment(id){
    if(selectedPayment.value){
            managePaymentStore.savePayment(window.localStorage.getItem('schedule_id'),route.params.id, window.localStorage.getItem('rate') );
            selectedPayment.value = null;
    }
    else {
        toast.error("Please select atleast one payment value !", {
                autoClose: 1000,
              });
    }
}
function selectPayment() {
    selectedPayment.value = true;
    
}
</script>

<style scoped>
.active-pay {
    border: 1px solid rgb(138, 138, 138);
    padding: 10px;
    border-radius: 5px;
}
</style>