import { defineStore } from "pinia";
const ID_TOKEN_KEY = "id_token";
const ID_TOKEN_KEY_AGENT = "id_token_agent";
import { isAgent } from "../../main";
export const useJwtService = defineStore("jwtservice", () => {
  function saveToken(token) {
    if (isAgent){

      window.localStorage.setItem(ID_TOKEN_KEY_AGENT, token);
    }
    else{
    window.localStorage.setItem(ID_TOKEN_KEY, token);
    }
  }
  function getToken(){
    if (isAgent){

      return window.localStorage.getItem(ID_TOKEN_KEY_AGENT);
    }
    else{
      return window.localStorage.getItem(ID_TOKEN_KEY);
    }
  }
  function destroyToken(){
    
    if (isAgent){

      window.localStorage.removeItem(ID_TOKEN_KEY_AGENT);
    }
    else{
    window.localStorage.removeItem(ID_TOKEN_KEY);
    }
  }
  return{getToken,saveToken,destroyToken}
});
