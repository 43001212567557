import { defineStore } from "pinia";
export const useComponentCardProfile = defineStore("componentcardprofile", {
  state: () => ({
    selectCardId: 0,
  }),
  getters: {
    getSelectCardId: (state) => {
      return state.selectCardId;
    },
    
  },
  actions: {
    selectAccount(id) {
      this.selectCardId =  id
    },
   
  },
});
