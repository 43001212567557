<template>
    <modalComp :idmodal="'multiplecharges'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Choose OPD Charge</h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <!-- <div class="strongpassword mt-4">Please choose a payment option
            </div> -->
            <!-- <div class="strongpassword">Please choose opd charge
            </div> -->

            <div style="justify-content: center;" class="d-flex">
                <div  data-bs-dismiss="modal" @click="chooseAmount(amt.patient_cat_id)" v-for="(amt, index) in getMultipleCharges" :key = index>
                    <button style="height: 100%" type="button" class="btn btn-primary me-2">
                        <span v-if="amt.patient_cat_id == 1">Dr. Sani Thapa</span>
                        <span v-else>Dr. Nilam Kumar Khadka <br> Dr. Prabhat Jha</span>
                        <br>
                        <hr style="margin: 0.5rem 0;">
                        <span style="font-weight: 700;"> Rs {{amt.amount }}</span>
                    </button>
                </div>
            </div>
        </template>
        <!-- <template v-slot:footer>
            <div class="col-6 m-0 p-0" v-for="(btns,index) in bookpay" :key = index>
                <button type="button" :class="btns.button_id == 1 ? 'btn-primary' : ''" class="btn border-top btn-lg w-100" @click="proceedPayment(btns.button_id)" data-bs-dismiss="modal"
          >{{btns.button_type}}</button>
            </div>
        </template> -->
    </modalComp>
</template>

<script setup>
    import { Modal } from 'bootstrap'
import modalComp from '@/components/common/modal'
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSelfAppointment } from "@/core/modules/self/appointment"
import router from "@/router/routes";
import { useRoute } from 'vue-router'
const { getMultipleCharges } = storeToRefs(useSelfAppointment())
const store = useSelfAppointment()
const route = useRoute()
const props = defineProps({
    deptId: Number
})

onMounted(async() => {
    let chargeModal = document.getElementById('multiplecharges')
    await chargeModal.addEventListener('shown.bs.modal', function (){
        store.getMultipeChargesForDepartmentOpd(props.deptId);
  })
})

function chooseAmount(id){
    store.getDepartmentOpdCharge(props.deptId, id)

}
</script>
