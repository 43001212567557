import axios from "axios";
// import ref from 'vue';
import { defineStore } from "pinia";
// import { toast } from 'vue3-toastify'

export const useAuthForgetPasswordRef = defineStore("authchangepasswordref", () => {
  // const password = ref("");
  // return { password }
})

export const useAuthForgetPassword = defineStore("authchangepassword", {
  state: () => ({
    errors: null,
    loaderStatus: false
  }),
  getters: {
    getErrors: (state) => {
      return state.errors
    },
    getLoaderStatus: (state) => {
      return state.loaderStatus
    }
  },
  actions: {
    async submitResetPassword(password_credentials) {
      try {
        this.setErrors(null)
        this.loaderStatus = true;
        await axios
          .post("/setPassword", password_credentials)
          .then((response) => {
            this.loaderStatus = false;
            if(response.data.status === 200){
              window.localStorage.removeItem('phoneNumber')
              this.$router.push('/login')
            }
  
          })
          .catch((error) => {
            this.loaderStatus = false;
            this.setErrors(error.response.data);
         
          })
      }
      catch (error) {
        this.loaderStatus = false;
    
      }
    },
    setErrors(error) {
      this.errors = error;
    },
  }
})