import axios from "axios";
import { defineStore } from "pinia";

export const useAuthOtpRef = defineStore("authOtpRef", () => {
  // const codes = ref("398249");
  // return codes
});

export const useAuthOtp = defineStore("authOtp", {
  state: () => ({
    errors: null,
    loaderStatus: false,
  }),
  getters: {
    getErrors: (state) => {
      return state.errors;
    },
    getLoaderStatus: (state) => {
      return state.loaderStatus;
    },
  },
  actions: {
    async submitOtp(codes) {
      try {
        this.setErrors(null)
        this.loaderStatus = true;
        await axios
          .post("/verifyOtp", codes)
          .then((response) => {
            this.loaderStatus = false;
            if (response.data.status === 200) {
              this.$router.push("/recover/password");
            }
          })
          .catch((error) => {
            this.loaderStatus = false;
            this.setErrors(error.response.data)
          });
      } catch (error) {
        this.loaderStatus = false;
      }
    },
    setErrors(error) {
      this.errors = error;
      },
  },
});
