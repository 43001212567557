import axios from "axios";
import { defineStore } from "pinia";
import { useJwtService } from "@/core/services/jwt";
export const useApiHeader = defineStore("apiheader", () => {
  function setHeader() {
   
    const jwtService = useJwtService();
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${jwtService.getToken()}`;
  }

  return {setHeader};
});
