<template>
    <div class="col-md-4 offset-md-4 auth-height">
      <div
        class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-hidden"
      >
        <div class="osahan-cart-item-profile bg-white p-3">
          <div class="p-50 d-flex flex-column">
            <h4>Verify Your Phone Number</h4>
            <p >
              Please check your phone for a text message with your code. Your code is 6 -digit long.
            </p>
            <form class="mt-4">
                <div class="mb-4 d-flex">
                    <input maxlength="1" v-model="otp_1" type="text" class="form-control otp-input me-2 text-center h-80">
                    <input maxlength="1" v-model="otp_2" type="text" class="form-control otp-input me-2 text-center h-80">
                    <input maxlength="1" v-model="otp_3" type="text" class="form-control otp-input me-2 text-center h-80">
                    <input maxlength="1" v-model="otp_4" type="text" class="form-control otp-input me-2 text-center h-80">
                    <input maxlength="1" v-model="otp_5" type="text" class="form-control otp-input me-2 text-center h-80">
                    <input maxlength="1" v-model="otp_6" type="text" class="form-control otp-input text-center h-80">
                    </div>
            </form>
            <button
              class="btn btn-primary btn-lg"
              style="margin-bottom: 30px"
              @click="onSubmit()"
              type="button"
              :disabled="getLoaderStatus"
            >
              <span class=" ml-2 text-lg">Verify</span>
              <span
                v-if="getLoaderStatus"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <span
              style="margin-left: auto; margin-right: auto"
              class="mb-3 fw-bold"
              >Didn't receive code?
              <a @click="resend_otp()" style="cursor: pointer; color: var(--foundation----primary---p300)">Click Here</a> to
              resend</span
            >
            <span
              style="margin-left: auto; margin-right: auto"
              class="mb-3 fw-bold"
              >
              <router-link :to="{ name: 'page.auth.login' }" class="text-decoration-none mt-3">
                          <p style="color: var(--foundation----primary---p300)" class="hover-text">Back to login</p>
                      </router-link>
              </span
            >
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { reactive, ref, onMounted } from "vue";
  import { storeToRefs } from "pinia";
  import { useAuthOtp, useAuthOtpRef } from "@/core/modules/auth/otp";
  import { useAuthResetPassword } from "@/core/modules/auth/resetpassword";
  const store = useAuthOtp();
  const stores = useAuthResetPassword()
  const storeref = useAuthOtpRef();
  const { getLoaderStatus } = storeToRefs(useAuthOtp());
  var { codes } = storeToRefs(storeref);
  var contact = ref()
  var hiddenContact = ref()
  onMounted(() => {
    // contact.value = window.localStorage.getItem("phoneNumber");
    // hiddenContact.value = contact.value.replace(contact.value.substring(3,8), "******")
    let arr = document.getElementsByClassName('otp-input');
    arr[0].focus();
    Array.from(arr).forEach((item) => {
    item.addEventListener('keyup', (event) =>{
        if(item.value){
            if(item.nextElementSibling){
            item.nextElementSibling.focus()
        }
        else {
            return;
        }
        }
        else if(event.keyCode === 8){
            if(item.previousElementSibling){
                item.previousElementSibling.focus();
                item.previousElementSibling.select()
            }
            else {
                return
            }
        }
    })
})
  })
  const  otp_1 =ref(null);
const  otp_2 =ref(null);
const  otp_3 =ref(null);
const  otp_4 =ref(null);
const  otp_5 =ref(null);
const  otp_6 =ref(null);
  function resend_otp(){
    var body = {
          'contact': contact.value,
          'device_id': 'web'
      }
      stores.submitReset(body)
  }
  function onSubmit() {
    let otp_code = parseInt(otp_1.value + otp_2.value + otp_3.value + otp_4.value + otp_5.value + otp_6.value);
    let contact_number = parseInt(window.localStorage.getItem("contact"));
    var body = {
        contact : contact_number,
        otp: otp_code,
        device_id : 'e78ad27aea950c14'
    }
    store.submitOtp(body);
  }
  </script>
  <style scoped>
  
  .p-50 {
    padding: 10px 40px;
  }

  .h-80 {
    height: 80px;
  }
  </style>
  