// import Router from 'vue-router'
import { createRouter, createWebHistory } from "vue-router";
import landingPage from "@/pages/landing";
import authLoginPage from "@/pages/auth/login";
import authSignUp from "@/pages/auth/signup";
import authTerms from "@/pages/auth/terms";
import authPrivacy from "@/pages/auth/privacy";
import authPasswordResetPage from "@/pages/auth/resetpassword";
import authOtpPage from "@/pages/auth/otp2";
import authRecoverPassword from "@/pages/auth/recoverpassword";
import authSelectAccount from "@/pages/manage/selectaccount";
import componentSideNav_Nav from "@/components/sidenav/nav";
import pageDashboard from "@/pages/dashboard";
import pageManageProfile from "@/pages/manage/profile";
import authChangePassword from "@/pages/manage/changepassword"
import selfMyAppointment from "@/pages/self/myappointment"
import selfPrescriptions from "@/pages/self/prescription"
import selfInvestigation from "@/pages/self/investigationreport"
import selfVitals from "@/pages/self/vitals"
import selfPurchaseHistory from "@/pages/self/purchasehistory"
import selfWellness from "@/pages/self/wellness"
import pagePage from "@/pages/page";
import pageErrorPageNotFound from "@/pages/errors/pagenotfound";
import pageDownloadApp from "@/pages/downloadapp";
import pageContactUs from "@/pages/contact-us";
import pageWellnessPackage from "@/pages/wellnesspackage";
import pageAppointmentDoctor from "@/pages/departmentDetail";
import pageAppointment from '@/pages/appointment'
import pageOpdAppointment from '@/pages/pageOpdAppointment'
import pageDoctorSchedule from "@/pages/doctorSchedule"
import pageSuccessAppointment from "@/pages/successappointment"
import pageDisclaimerAndPrivacy from "@/pages/disclaimerandprivacy"
import pagePaymentResponse from "@/pages/paymentresponse";
import pageDoctorWithSpecialities from "@/pages/pageDoctorWithSpecialities"
import pageDocSlotAppointment from "@/pages/pageDocSlotAppointment"
import pageImmegrationFormAppointment from "@/pages/pageImmegrationFormAppointment"

const routes = [
  {
    name: "page.download.app",
    component: pageDownloadApp,
    path: "/download-app",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.landing",
    component: landingPage,
    path: "/",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.login",
    component: authLoginPage,
    path: "/login",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.signup",
    component: authSignUp,
    path: "/signup",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.resetpassword",
    component: authPasswordResetPage,
    path: "/forgot-password",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.otp",
    component: authOtpPage,
    path: "/otp",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.privacy",
    component: authPrivacy,
    path: "/privacy",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.terms",
    component: authTerms,
    path: "/terms",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.recover.password",
    component: authRecoverPassword,
    path: "/recover/password",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.manage.selectaccount",
    component: authSelectAccount,
    path: "/manage/select-account",
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "page.dyanamic.page",
    component: pagePage,
    path: "/page",
    meta: {
      requiresAuth: false,
    },
  },
  {
    component: componentSideNav_Nav,
    path: "/",
    children: [
      {
        name: "page.dashboard",
        component: pageDashboard,
        path: "/dashboard",
        meta: {
          requiresAuth: true,
          title: "Dashboard",
        },
      },

      {
        name: "page.selectaccount",
        component: authSelectAccount,
        path: "/select-account",
        meta: {
          requiresAuth: true,
          title: "Select Account",
          desc: "Select account to view all the realted user",
        },
      },

      {
        name: "page.manage.profile",
        component: pageManageProfile,
        path: "/profile/me",
        meta: {
          requiresAuth: true,
          title: "My Account",
          desc: "View your profile",
        },
      },
      {
        name: "page.manage.changepassword",
        component: authChangePassword,
        path: "/profile/change-password",
        meta: {
          requiresAuth: true,
          title: "Change Password",
        },
      },
      {
        name: "page.self.appointment",
        component: selfMyAppointment,
        path: "/self/appointment",
        meta: {
          requiresAuth: true,
          title: "My Appointments"
        },
      },
      {
        name: "page.self.prescriptions",
        component: selfPrescriptions,
        path: "/self/prescriptions",
        meta: {
          requiresAuth: true,
          title: "My Prescriptions"
        },
      },
      {
        name: "page.self.investigation",
        component: selfInvestigation,
        path: "/self/investigation-report",
        meta: {
          requiresAuth: true,
          title: "My Investigation Reports"
        },
      },
      {
        name: "page.self.vitals",
        component: selfVitals,
        path: "/self/vitals",
        meta: {
          requiresAuth: true,
          title: "My Vitals"
        },
      },
      {
        name: "page.self.purchase-history",
        component: selfPurchaseHistory,
        path: "/self/purchase-history",
        meta: {
          requiresAuth: true,
          title: "My Purchase"
        },
      },
      {
        name: "page.self.wellness",
        component: selfWellness,
        path: "/self/wellness",
        meta: {
          requiresAuth: true,
          title: "My Wellness"
        },
      },
    ],
  },
  {
    name: "page.contact-us",
    component: pageContactUs,
    path: "/contact-us",
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "page.wellnesspackage",
    component: pageWellnessPackage,
    path: "/wellness-package",
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "page.appointment",
    component: pageAppointment,
    path: "/appointment",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.appointmentdoctor",
    component: pageAppointmentDoctor,
    path: "/appointment/doctor/:id",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.successappointment",
    component: pageSuccessAppointment,
    path: "/appointment/success",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.doctorschedule",
    component: pageDoctorSchedule,
    path: "/appointment/doctor/schedule",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.disclaimerandprivacy",
    component: pageDisclaimerAndPrivacy,
    path: "/disclaimer-privacy",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/confirmpayment",
    name: "page.paymentresponse",
    component: pagePaymentResponse,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "page.opdDept",
    component: pageOpdAppointment,
    path: "/opdDept/:country_id",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.docWithSpecialities",
    component: pageDoctorWithSpecialities,
    path: "/doctorWithSpecialities/:id/:country_id",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.docSlot",
    component: pageDocSlotAppointment,
    path: "/doctor/slot/:id/:country_id",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.immgBook",
    component: pageImmegrationFormAppointment,
    path: "/doctor/slot/:id/:country_id/request-callback",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.login.agent",
    component: authLoginPage,
    path: "/agent/login",
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "page.auth.login.agent",
    component: authLoginPage,
    path: "/agent/login",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: pageErrorPageNotFound,
    meta: {
      requiresAuth: false,
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
