import axios from "axios";
import { defineStore } from "pinia";
export const useSelfAddress = defineStore('selfAddress', {
    state: () => ({
        errors : [],
        provinces : [],
        provinceDistrict: [],
        municipalities: [],
        countries: [],
        loaderStatus: true
    }),
    getters: {
        getErrors: (state) => {
            return state.errors
        },
        getProvinces: (state) => {
            return state.provinces
        },
        getProvinceDistrict: (state) => {
            return state.provinceDistrict;
        },
        getMunicipality: (state) => {
            return state.municipalities;
        },
        getCountries: (state) => {
            return state.countries;
        }
    },
    actions: {
        async getCountriesData() {
            try {
                this.loaderStatus = true;
                await axios
                    .get('fetchCountry')
                    .then((response) => {
                        this.loaderStatus = false;
                        this.countries = response.data;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getProvincesData() {
            try {
                this.loaderStatus = true;
                await axios
                    .get('fetchProvince')
                    .then((response) => {
                        this.loaderStatus = false;
                        this.provinces = response.data;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getProvinceDistrictData(id) {
            try {
                this.loaderStatus = true;
                await axios
                    .get('fetchProvinceDistrict/'+id)
                    .then((response) => {
                        this.loaderStatus = false;
                        this.provinceDistrict = response.data;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                
                this.loaderStatus = false;
            }
        },
        async getMunicipalityData(id) {
            try {
                this.loaderStatus = true;
                await axios
                    .get('fetchDistrictMunicipality/'+id)
                    .then((response) => {
                        this.loaderStatus = false;
                        this.municipalities = response.data;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                
                this.loaderStatus = false;
            }
        }
    }
})