<template>
    <!-- <div class="row"> -->
        <div class="col-sm-12">
            <div class="rounded shadow-sm">
                <div class="osahan-privacy bg-white rounded shadow-sm p-4">
                    <!-- <div class="row">
                        <div class="col-sm-2" style="cursor: pointer;" v-if="getIsAuthorized">
                            <router-link :to="{ name: 'page.dashboard' }">
                                <font-awesome-icon icon="fa-solid fa-arrow-left" style=" margin-right: 10px;" />
                                <span>Back</span>
                            </router-link>
                        </div>
                    </div> -->

                    <div class="row appointment-header ">
                        <!-- <div class="appointment-tabs col-sm-10">
                            <tabList @click="clickTab(tabs, index)" :class="{ activeTab: index == selectedTab }"
                                class="dateTabList" v-for="(tabs, index) in appointment_tabs" :key="index" :date="tabs">
                            </tabList>
                        </div> -->
                        <div  :class="getIsAuthorized ? 'col-sm-5' : ''" style="cursor: pointer;" v-if="getIsAuthorized">
                            <router-link :to="{ name: 'page.dashboard'  }" >
                                <font-awesome-icon icon="fa-solid fa-arrow-left" style=" margin-right: 10px;" />
                                <span>Back</span>
                            </router-link>
                                    
                            
                        </div>
                        <div :class="getIsAuthorized ? 'col-sm-5' : 'col-sm-10 col-md-8 col-xl-9'">
                            <div class="all-department">
                                <span style="font-size: var(--heading-3---medium)" class="fw-bold">Online OPD Appointment</span>
                            </div>
                        </div>
                        <div class="col-sm-2" :class="getIsAuthorized ? 'col-sm-2' : 'col-md-4 col-xl-3'" style="position: relative">
                            
                            <input type="text" v-model="filter" class="form form-control text-lg"
                            style="font-size: 1.3rem;"
                                placeholder="Search Specialities, Doctors" />
                            <font-awesome-icon class="searchIcon" icon="fa-solid fa-search" />
                        </div>
                    </div>
                    
                <div class="row py-4">
                    <h5> Specialities</h5>
                    <hr/>
                    <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>
                    <noRecordComp v-if="filteredSpecialites?.length < 1 && !getLoaderStatus"
                        :title="'NO RECORD FOUND'">
                    </noRecordComp>
                
                    <div v-if="!getLoaderStatus" class="row department-list">
                        <div class="col-12 col-md-4 col-xl-3" v-for="(specialities, index) in filteredSpecialites" :key="index">
                            <div class="packages"
                                @click="fetchDoctors(specialities)">
                                <div style="width: 60px; height: 60px; border-radius: 10px;">
                                    <img  :src="specialities.image" style=" object-fit: contain; height: 60px; width: 100%; "/>
                                </div>
                                <div class="package_name">
                                    <span class="fo-text-color-primary-p500 fw-bold d-flex justify-content-center align-items-center">{{
                                        specialities.name
                                    }}</span>
                                </div>
                                <div class="right-icon">
                                    <font-awesome-icon class="fo-text-color-primary-p500" icon="fa-solid fa-arrow-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row py-4" v-if="filter.length">
                    <h5> Doctors</h5>
                    <hr/>
                    <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>
                    <noRecordComp v-if="filteredDoctors?.length < 1 && !getLoaderStatus"
                        :title="'NO RECORD FOUND'">
                    </noRecordComp>
                
                    <div v-if="!getLoaderStatus" class="row department-list">
                        <div class="col-12 col-md-4 col-xl-3" v-for="(doc, index) in filteredDoctors" :key="index">
                            <div class="packages"
                                @click="fetchDoctors(doc)">
                                <div style="width: 60px; height: 60px; border-radius: 4px;">
                                    <img  :src="doc.image" style=" object-fit: contain; height: 60px; width: 100%; "/>
                                </div>
                                <div class="package_name">
                                    <span class="fo-text-color-primary-p500 fw-bold d-flex justify-content-center align-items-center">{{
                                        doc.doctor_name
                                    }}</span>
                                </div>
                                <div class="right-icon">
                                    <font-awesome-icon class="fo-text-color-primary-p500" icon="fa-solid fa-arrow-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <!-- <multiplecharges :deptId = "department"></multiplecharges> -->

</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, computed } from "vue";
import { storeToRefs } from "pinia";
import SpinnerDataLoader from "@/components/loaders/spinnerloader";
import noRecordComp from "@/components/norecord";
import { useSelfAppointment } from '@/core/modules/self/appointment';
import {useStoreAppointment} from "@/core/modules/appointment/appointment"
import {useManageUser} from '@/core/modules/auth/user'
// import multiplecharges from '@/components/modal/multiplecharges'

const { getIsAuthorized}  =  storeToRefs(useManageUser())
const store = useSelfAppointment();
const storeAppointment=useStoreAppointment()
const dept_id = ref(null)
// const is_reschedule = ref(false)

const {getLoaderStatus,getfetechedSpecialitiesList}=storeToRefs(useStoreAppointment())
// const selectedTab = ref("0");
const filter = ref("");

const router = useRouter();

onMounted(async () => {
    // store.fetchImmigrationDoctorDetails();]
    await storeAppointment.fetchSpecialities()
    await store.getDepartmentList();
})

function fetchDoctors(body) {
    if(body.code){
        // go to dept route
       router.push({name: "page.docWithSpecialities",params:{
        id:body.id
       }})
    
    }
    else{
        //  go to doc desc
        router.push({name: "page.docSlot",params:{
        id:body.id,
       }})
    }
  
}
// const department = computed(() => {
//     return dept_id.value
// })


const filteredSpecialites = computed(() => {
    
    let filteredData = getfetechedSpecialitiesList.value?.specialities;
    if (filteredData)
        return getfetechedSpecialitiesList.value?.specialities?.filter((sepecalits) => {
            return sepecalits.name
                .toLowerCase()
                .includes(filter.value.toLowerCase());
        });
    else return filteredData;
});
const filteredDoctors = computed(() => {
    let filteredData = getfetechedSpecialitiesList.value?.doctors;
    if (filteredData)
        return getfetechedSpecialitiesList.value?.doctors?.filter((doctor) => {
            return doctor.doctor_name
                .toLowerCase()
                .includes(filter.value.toLowerCase());
        });
    else return filteredData;
});
</script>

<style scoped>
.appointment-tabs {
    display: flex;
}
</style>
