<template>
    <div class="download-app-bg">
       
    </div>
</template>

<style lang="css" scoped>
    .download-app-bg{
        width: 100%;
        height: calc(100vh);
        position: fixed;
        background-origin: calc(100vh);
        background-size: cover;
        background-repeat: no-repeat;
        background: url('@/assets/images/bg-mobile.jpg');
    }
</style>