<template>
    <modalComp :idmodal="'rescheduleappointment'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Reschdeule Appointment</h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <div class="strongpassword"><span>Are you sure you want to reschedule the appointment of<br></span><span
                    class="fw-bold"> {{ doc_name }}</span> <span> for </span><span class="fw-bold"> {{ appointment_time }}, {{ appointment_date }}? </span>
            </div>
        </template>
        <template v-slot:footer>
            <div v-if="rescheduleBookFlag === false" class="col-6 m-0 p-0">
                <button data-bs-dismiss="modal" @click="rescheduleAppointment()" type="button"
                    class="btn btn-primary btn-lg w-100">Yes
                </button>
            </div>
            <div v-else class="col-6 m-0 p-0">
                <button @click="bookRescheduleAppointment()" type="button" class="btn btn-primary btn-lg w-100"
                    data-bs-dismiss="modal">
                    Reschedule
                </button>
            </div>
        </template>

    </modalComp>
</template>

<script setup>
import modalComp from '@/components/common/modal'
import { storeToRefs } from 'pinia'
import { useSelfAppointment } from "@/core/modules/self/appointment"
import { onMounted, ref } from 'vue';
const { getCurrentScheduleId, getBookingId } = storeToRefs(useSelfAppointment())
const store = useSelfAppointment()
const is_reschedule = ref(true)
const rescheduleBookFlag = ref(null)
const props = defineProps({
    appointment_time: String,
    appointment_date: String,
    doc_name: String,
    immigration_doctor: Object,
    target_schedule: Number,
    book_reschedule: Boolean,
    appoinment: Number
})
const year = ref('')
const month = ref('')

onMounted(() => {
    rescheduleBookFlag.value = props.book_reschedule
})

function rescheduleAppointment() {
    store.getScheduleDates(props.immigration_doctor.id, props.immigration_doctor.speciality_id, year.value, month.value, props.immigration_doctor, is_reschedule.value)
}

function bookRescheduleAppointment() {
    var body = {
        booking_id: getBookingId.value.data,
        current_schedule_list_id: getCurrentScheduleId.value,
        target_schdeule_list_id: props.target_schedule

    }
    store.reschedule(body)
}
</script>
