<template>
    <div class="col-md-4 offset-md-4 auth-height">
        <div class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-hidden">
            <div class="osahan-cart-item-profile bg-white p-3">
                <div class="d-flex flex-column">
                    <h6 class="mb-3 fw-bold">Please enter your mobile number to reset your password</h6>
                    <form action="">
                        <div v-if="getErrors ? (getErrors['errors'] && getErrors['status'] == 404 ? true : false) : false"
                                class="alert alert-danger" role="alert">
                                {{ getErrors['message'] }}
                            </div>
                        <div class="form-group mb-3">
                            <label for="exampleFormControlInput1" class="small fw-bold pb-1">Mobile Number</label>
                            <input @keydown.enter="onSubmit()" type="text" class="form-control" v-model="phoneNumber" placeholder="Enter your mobile number">
                            <label for=""
                                    v-if="getErrors ? (getErrors['errors']['contact'] ? true : false) : false"
                                    class="text-danger"> {{ getErrors ? (getErrors["errors"]['contact'] ?
                                        getErrors["errors"]['contact'][0] : "") : "" }}</label>
                        </div>
                    </form>
                    <button class="btn btn-primary btn-lg"  @click="onSubmit()" type="button" :disabled="getLoaderStatus">
                      <span class=" ml-2 text-lg">Continue</span>  <span v-if="getLoaderStatus" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                    <router-link :to="{ name: 'page.auth.login' }" class="text-decoration-none mt-3">
                        <p class="text-left hover-text">Back to login</p>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthResetPassword, useAuthResetPasswordRef } from "@/core/modules/auth/resetpassword"
const store = useAuthResetPassword()
const storeref = useAuthResetPasswordRef()
const { getLoaderStatus, getErrors } = storeToRefs(useAuthResetPassword())
const { phoneNumber } = storeToRefs(storeref)

onMounted(() => {
    phoneNumber.value = ''
})
function onSubmit() {
    var body = {
        'contact': phoneNumber.value,
        'device_id': 'web'
    }
    store.submitReset(body)
}

</script>