<template>
    <div class="d-flex align-items-center p-3 mb-2"
        :class="bg ? getSelectCardId != patientid ? 'fo-bg-color-secondary-s50 rounded cursor-pointer border-s2 fo-border-color-secondary-s50' : 'fo-bg-color-success-s50  rounded border-s2 fo-border-color-success' : 'fo-bg-color-primary-p50'"
        @click="selectAccount(patientid, token)">
       
        <div class="left me-3">
            <img src="@/assets/images/user.png" style="width: 60px;" class="rounded-circle">
        </div>
        <div class="right">
            <h6 class="mb-0 fw-bold">{{ patientid }}<i class="feather-check-circle text-success"></i>
            </h6>
            <p class="text-dark m-0 fw-bold">{{ patientname }} </p>
            <p class="text-primary m-0 small"><i class="fa fa-calender"></i>{{ regdate }}</p>
        </div>
    </div>
</template>


<script setup>
import { useComponentCardProfile } from '@/core/modules/components/card/profile'
import { useSelectAccount } from '@/core/modules/manage/selectaccount'
import { storeToRefs } from 'pinia';
import {useManageUser} from '@/core/modules/auth/user'
import { onMounted } from 'vue';
const props = defineProps({
    patientid: Number,
    patientname: String,
    regdate: String,
    avatar: String,
    token: String,
    bg: Boolean
});
const componentCardProfile = useComponentCardProfile();
const selectAccountStore = useSelectAccount();
const manageUserStore =  useManageUser();
const { getSelectCardId } = storeToRefs(useComponentCardProfile())
const {getUser} = storeToRefs(useManageUser())
const emit = defineEmits(['setselecttoken'])

onMounted(()=>{
    
    componentCardProfile.selectAccount(manageUserStore.getUser.patient_id);
})
function selectAccount(patient_id, token = '') {
    
    componentCardProfile.selectAccount(patient_id);

    if (token != '') {
        selectAccountStore.setSelectToken(token)
    }



}
</script>