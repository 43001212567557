import axios from "axios";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Modal } from 'bootstrap'

export const useSelfWellness = defineStore("selfWellness", {
    state: () => ({
        errors: null,
        loaderStatus: false,
        wellnessData: "",
        todayData: "",
        wellnessPackageData: "",
        selectedWellnessPlan: "",
        selectedWellnessPlansSerices:"",
        package_items: "",
        similar_services: "",
        booked_data:'',
        paymentInfo:""
    }),
    getters: {
        getErrors: (state) => {
            return state.errors;
        },
        getLoaderStatus: (state) => {
            return state.loaderStatus
        },
        getWellnessData: (state) => {
            return state.wellnessData
        },
        getTodayData: (state) => {
            return state.todayData
        },
        getWellnessPackageData: (state) => {
            return state.wellnessPackageData
        },
        getSelectedWellnessPlan: (state) => {
            return state.selectedWellnessPlan
        },
        getSelectedWellnessPlansSerices:(state)=>{
            return state.selectedWellnessPlansSerices
        },
        getPackageItems: (state) => {
            return state.package_items
        },
        getSimilarServices: (state) => {
            return state.similar_services
        },
        getCurrentBookedData:state=>state.booked_data,
        getCurrentPaymentInfo:state=>state.paymentInfo
    },
    actions: {
        async getWellness(tab,patient_id) {
            try {
                this.setErrors(null)
                this.loaderStatus = true;
                await axios
                    .get("/bookedWellnessHistory/"+tab+'/'+patient_id )
                    .then((response) => {
                        this.loaderStatus = false;
                        this.wellnessData = response.data.appointment_history.data;
                        this.todayData = response.data.appointment_history.today;
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data)
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                console.log(error)
            }
        },
        async cancel_wellness_package(inv, id, tab) {
            try{
                this.setErrors(null)
                this.loaderStatus = true;
                await axios
                    .get("/cancelBookedWellness/"+inv)
                    .then((response) => {
                        this.loaderStatus = false;
                        if(response.data.status === 200){
                            toast.success(response.data.message, {
                                autoClose: 1000,
                              });
                        this.getWellness(tab, id);

                    }
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data)
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                console.log(error)
            }
        },
        setErrors(error) {
            this.errors = error;
        },
        async getWellnessPackage() {
            try {
                this.setErrors(null)
                this.loaderStatus = true;
                await axios
                    .get("getOnlineItems" )
                    .then((response) => {
                        this.loaderStatus = false;
                        this.wellnessPackageData = response.data.data;
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data)
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                console.log(error)
            }
        },
        async fetchWellnessPlanData(id) {
            try {
                this.setErrors(null)
                this.loaderStatus = true;
                this.selectedWellnessPlan = ''
                this.selectedWellnessPlansSerices=''
                await axios
                    .get("fetchOnlineService/"+id )
                    .then((response) => {
                        this.loaderStatus = false;
                        this.selectedWellnessPlan = response.data.data[0];
                        this.selectedWellnessPlansSerices=response.data.package_items
                        this.package_items = response.data.package_items;
                        this.similar_services = response.data.similar_service;
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data)
                        this.loaderStatus = false;
                        this.selectedWellnessPlan = ''
                this.selectedWellnessPlansSerices=''
                    })
            }
            catch(error) {
                console.log(error)
                this.selectedWellnessPlan = ''
                this.selectedWellnessPlansSerices=''
            }
        },
        async onlineBookWellness(body, bp){
            try {
                this.setErrors(null)
                this.loaderStatus = true;
                this.booked_data=''
                await axios
                    .post("onlinePurchaseBook",'',{params:body} )
                    .then(async (response) => {
                        if(response.data.status === 200){
                            this.loaderStatus = false;
                            this.booked_data=response.data
                            await this.fetchWellnessPaymentInfo(response.data)
                            toast.success(response.data.msg, {
                                autoClose: 1000,
                              })
                            
                            if(bp === 'pay'){
                            let  modal1 = Modal.getOrCreateInstance('#paywellness');
                            modal1.show();}
                            else {
                                this.$router.push({ name: "page.self.wellness" });
                            }
                        }
                        else if(response.data.status === 400){
                            this.loaderStatus = false;
                            Swal.fire({
                                title: 'Error',
                                text: response.data.msg,
                                icon: 'error',
                                confirmButtonText: 'OK'
                              })
                        }
                        else if(response.data.status === 500){
                            this.loaderStatus = false;
                            Swal.fire({
                                title: 'Error',
                                text: 'Please Select Date and Time',
                                icon: 'error',
                                confirmButtonText: 'OK'
                              })
                        }
                    })
                    .catch((error) => {
                        // this.setErrors(error.response.data)
                        this.loaderStatus = false;
                        console.log(error)
                    })
            }
            catch(error) {
                console.log(error)
            }
        },
        async fetchWellnessPaymentInfo(params){
            try {
                this.setErrors(null)
                this.loaderStatus = true;
                this.paymentInfo=''
                
                await axios
                    .get("/getWellnessPaymentInfo/",{params:{...params,'request_source' :'WEB'}} )
                    .then((response) => {
                    this.paymentInfo=response.data
                    })
                    .catch((error) => {
                        // this.setErrors(error.response.data)
                        this.loaderStatus = false;
                        console.log(error)
                    })
            }
            catch(error) {
                console.log(error)
            }
        },
        
        
    }
})