<template>
    <rescheduleappointment :book_reschedule="bookreschedule" :immigration_doctor="getImmigrationDoctorDetail" :doc_name="docname" :appointment_time="appointmenttime" :appointment_date="appointmentdate"></rescheduleappointment>
    <viewappointment :appointment_detail="appointmentDetail"></viewappointment>
    <div class="row">
        <div class="col-sm-12" v-if="getTodayData.length > 0">
            <span style="color:var(--foundation----primary---p200)" class="fw-700">Today</span>
            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>   
            <div style="margin-bottom: 20px; " v-if="!getLoaderStatus && getTodayData.length > 0" >
            <table class="self-table" >
                <thead>
                    <tr>
                        <th>Schedule ID</th>
                        <th>Doctor Name</th>
                        <th>Appointment Date</th>
                        <th>Reschedule</th>
                        <th>Visit Status</th>
                        <th class="width-30"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tableRow" v-for="(appointment,index) in displayedTodayRecord" :key="index">
                        <td>{{ appointment.id }}</td>
                        <td>{{ appointment.doctor_name }}</td>
                        <td>{{ appointment.full_datetime }}</td>
                        <td @click="reschedule_appointment(appointment.date, appointment.st_fulltime, appointment.doctor_name, appointment.can_reschedule, appointment.id, appointment.full_datetime)"><span class="visit_status" :class="appointment.can_reschedule === 1 ? 'can_reschedule_yes': 'can_reschedule_no'">Reschedule</span></td>
                        <td><span class="visit_status" :class="appointment.visit_status == 'Not visited' ? 'visit_status_no' : 'visit_status_yes'">{{ appointment.visit_status }}</span></td>
                        <td style="cursor: pointer" @click="viewAppointment(appointment)" class="text-center"><font-awesome-icon class="fo-text-color-primary-p300" :icon="'fa-solid fa-eye'"/></td>
                    </tr>
                </tbody>
            </table>
            <pagination v-model="page" :records="getTodayData.length" :per-page="perPage" @paginate="myCallback"/>
        </div>
        </div>
        <div class="col-sm-12">
            <div class="wellness-tabs">
            <tabList :class="{ activeTab: index == selectedTab }" @click="clickTab(tabs,index)"  class="dateTabList" v-for="(tabs,index) in wellness_tabs" :key="index" :date="tabs"></tabList>
            </div>
            <noRecordComp v-if="getAppointmentHistory.length < 1 && !getLoaderStatus" :title="'NO RECORD FOUND'"></noRecordComp>
            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>    
            <div v-if="!getLoaderStatus && getAppointmentHistory.length > 0">
            <table class="self-table" >
                <thead>
                    <tr>
                        <th>Schedule ID</th>
                        <th>Doctor Name</th>
                        <th>Appointment Date</th>
                        <th>Reschedule</th>
                        <th>Visit Status</th>
                        <th class="width-30"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tableRow" v-for="(appointment,index) in displayedRecords" :key="index">
                        <td>{{ appointment.id }}</td>
                        <td>{{ appointment.doctor_name }}</td>
                        <td>{{ appointment.full_datetime }}</td>
                        <td  @click="reschedule_appointment(appointment.date, appointment.st_fulltime, appointment.doctor_name, appointment.can_reschedule, appointment.id, appointment.full_datetime)"> <span class="visit_status" :class="appointment.can_reschedule === 1 ? 'can_reschedule_yes': 'can_reschedule_no'">Reschedule</span></td>
                        <td ><span class="visit_status" :class="appointment.visit_status == 'Not visited' ? 'visit_status_no' : 'visit_status_yes'">{{ appointment.visit_status }}</span></td>
                        <td style="cursor: pointer" @click="viewAppointment(appointment)" class="text-center"><font-awesome-icon class="fo-text-color-primary-p300" :icon="'fa-solid fa-eye'"/></td>
                    </tr>
                </tbody>
            </table>
            <pagination v-model="page" :records="getAppointmentHistory.length" :per-page="perPage" @paginate="myCallback"/>
        </div>
        </div>

        
    </div>
</template>
<script setup>

import '@/assets/css/pagination.css'
import viewappointment from '@/components/modal/viewappointment'
import {useManageUser} from '@/core/modules/auth/user'
// import Pagination from '@/components/pagination/pagination'
import Pagination from 'v-pagination-3';
import { onMounted,computed,ref } from 'vue';
import { storeToRefs } from 'pinia'
import tabList from '@/components/tabList/tabList';
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import noRecordComp from '@/components/norecord'
import { useSelfAppointment } from "@/core/modules/self/appointment"
import rescheduleappointment from '@/components/modal/rescheduleapp'
import { Modal } from 'bootstrap'
const todayPage = ref(1)
const page = ref(1)
const perPage = ref(5)
const store = useSelfAppointment()
const wellness_tabs = ref(['upcoming', 'previous'])
var selectedTab = ref('0')
var activeTabName = ref("upcoming")
var docname = ref("")
var appointmentdate = ref('')
var appointmenttime = ref(null)
var immigration_doctor = ref('')
var bookreschedule = ref(false)
const appointmentDetail = ref(null)

const {getUser} =  storeToRefs(useManageUser())
const { getLoaderStatus, getAppointmentHistory, getTodayData, getErrors, getImmigrationDoctorDetail, getDoctorSchedule } = storeToRefs(useSelfAppointment())



onMounted(() => {
    store.fetchAppointmentHistory(`upcoming`,getUser.value.patient_id )
    store.fetchImmigrationDoctorDetails();
   
})

function clickTab(tabs,index){
    selectedTab.value = index;
    activeTabName.value = tabs
    store.fetchAppointmentHistory(tabs,getUser.value.patient_id)
}
function reschedule_appointment(date, time, doc, reschedule, id, datetime){
    immigration_doctor.value = getImmigrationDoctorDetail.value
    appointmentdate.value = date;
    appointmenttime.value = time;
    docname.value = doc;
    store.getAppointmentId(id)
    if(reschedule === 1){
        let  modal = Modal.getOrCreateInstance('#rescheduleappointment');
        modal.show();}
    else {
       return
    }
}
function myCallback() {
}
const displayedRecords = computed(() => {
    const startIndex = perPage.value * (page.value - 1);
    const endIndex = startIndex + perPage.value;
    return getAppointmentHistory.value.slice(startIndex, endIndex)
})
const displayedTodayRecord = computed(() => {
    const startIndex = perPage.value * (todayPage.value - 1);
    const endIndex = startIndex + perPage.value;
    return getTodayData.value.slice(startIndex, endIndex)
})

function viewAppointment(appointment) {
    appointmentDetail.value = appointment;
    let  modal1 = Modal.getOrCreateInstance('#viewappointment');
    modal1.show();
}
</script>

<style scope>

.visit_status_no {
    background-color: var(--foundation----danger---d100);
}
.visit_status_yes {
    background-color: var(--foundation----success---s100);
}
.can_reschedule_yes {
    background-color: var(--foundation----success---s100);
   
}

.visit_status {
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    color: var(--foundation---shades---s0)
}

.can_reschedule_no {
    display: none;

}
.width-30 {
    width: 30px;
}
</style>