<template>
    <!-- <div class="row"> -->
        <div class="col-sm-12">
            <div class="rounded shadow-sm">
                <div class="osahan-privacy bg-white rounded shadow-sm p-4">
                    <div class="row px-5">
                        <p style="font-size: var( --heading-6---medium)">
                            We appreciate you selecting Meraki Health Solutions for your medical requirements.
                            It is crucial for you to comprehend the rules and regulations that apply to your use of our
                            patient portal as a user.
                            The ways in which we gather, utilize, and protect your personal and health information through
                            our patient portal are described in this disclaimer and privacy policy.
                        </p>
                        <p style="font-size: var(--heading-3---bold);text-decoration: underline; text-underline-offset: 8px; color: var(--foundation----primary---p200)" class="fw-bold">
                            Privacy Policy
                        </p>

                        <span style="color: var(--foundation----primary---p200)" class="fw-bold">
                            Collection of Personal and Health Information:</span>
                             <span> 
                                Your name, email address, phone number, location, medical history, and other pertinent health information may be collected from you when you use our patient portal. We might also gather delicate personal data, like details on your medical conditions, prescription drugs, and treatments.
                            <br>
                            <br>
                            </span>

                            <span class="fw-bold" style="color: var(--foundation----primary---p200)">
                                Use of Personal and Health Information:
                            </span>
                            <span>
                                In order to deliver and enhance our services, connect with you, and comply with legal and regulatory requirements, we use the personal and health information we gather through our patient portal. With your express permission, we may also use your personal and health information for study.
                                <br>
                            <br>
                            </span>
                            
                            <span class="fw-bold" style="color: var(--foundation----primary---p200)">
                                Sharing of Personal and Health Information:</span>
                            <span>
                                Your personal or medical information is not sold to any parties. Nonetheless, in order to provide you with our services, we might disclose your personal and health information to our reliable service providers. In accordance with legal or regulatory obligations, we might also disclose your personal and health information to regulatory bodies or law enforcement organizations.    
                            <br>
                            <br>
                            </span>
                            <span class="fw-bold" style="color: var(--foundation----primary---p200)">
                                Data Security:</span>
                            <span>
                                We implement the necessary safeguards to prevent unauthorized access, disclosure, alteration, or destruction of your personal and health information. To keep your personal and health information safe and secure, we put in place physical, technical, and administrative protections.    
                            <br>
                            <br>
                            </span>
                            <span class="fw-bold" style="color: var(--foundation----primary---p200)">
                                Retention of Personal and Health Information:</span>
                            <span>
                                For as long as it takes to offer you with our services and to meet our legal and regulatory duties, we keep your personal and health information on file. With your express permission, we may also keep your personal and medical information for research purposes.    
                            <br>
                            <br>
                            </span>
                            <span class="fw-bold" style="color: var(--foundation----primary---p200)">
                                Your Rights:
                            </span>
                            <span>
                                You have the right to see and change any personal or medical information that we may have on you. Also, you have the right to request that we limit how we use your personal and health information or to object to how it is being processed. Please get in touch with us using the details shown below if you want to exercise these rights.    
                            <br>
                            <br>
                            </span>
                            <span class="fw-bold" style="color: var(--foundation----primary---p200)">
                                Changes to this Privacy Policy:
                            </span>
                            <span>
                                We may update this privacy policy from time to time. Any changes to this privacy policy will be posted on our website.
                            <br>
                            <br>
                            </span>
                            <span class="fw-bold" style="color: var(--foundation----primary---p200)">
                                Contact Us:
                            </span>
                            <span>
                                If you have any questions or concerns about our privacy policy or how we handle your personal and health information, please contact us at 
                                <span class="fw-bold">info@merakihealthsolutions.com</span> 
                                <br>
                                <br>
                            </span> 
                        <p class="fw-bold" style="font-size: var(--heading-3---bold); text-decoration: underline; text-underline-offset: 8px;  color: var(--foundation----primary---p200)">
                        Disclaimer
                        </p>
                        <span>
                        Please be aware that the information displayed, transmitted, or carried by Meraki Health Solution and its affiliated websites, including but not limited to directories, guides, news articles, opinions, reviews, text, photographs, images, illustrations, profiles, audio clips, video clips, trademarks, service marks, and the like, collectively the "Content," is protected by copyright and other intellectual property laws as per the government of Nepal and should be aware that neither the information nor the solicitation of business contained within are intended to be a substitute for expert medical advice.
                        <br>
                        <br>
                        Owners of the Content include Meraki Health Soultion, its affiliates, and any third-party licensors. Any content that you change, publish, transmit, transfer, sell, reproduce, distribute, repost, perform, exhibit, or otherwise commercially exploit is strictly prohibited. You agree not to change the content in any way and to follow all copyright notices and limitations related to any Content accessible through the Meraki Health Solution website.
                        <br>
                        <br>
                        Permitted Use: You are allowed to print out one copy of any content that is available on the Meraki Health Solution for your own personal, non-commercial use as long as you leave any trademark and other legal notices intact. Without written consent, you may not archive or otherwise keep any Content. The only goal of the content on this website is to spread health knowledge for the overall good.
                        </span>    
                </div>
            </div>
        </div>
    <!-- </div> -->
</div></template>