import axios from "axios";
import { defineStore } from "pinia";

export const useSelfVitals = defineStore("selfVitals", {
  state: () => ({
    errors: null,
    loaderStatus: false,
    vitalsData: "",
    latestVitalData: "",
    filterData: "",
    trpGraphData:""
  }),
  getters: {
    getErrors: (state) => {
      return state.errors;
    },
    getLoaderStatus: (state) => {
      return state.loaderStatus;
    },
    getVitalsData: (state) => {
      return state.vitalsData;
    },
    getLatestVitalData: (state) => {
      return state.latestVitalData
    },
    getTrpGraphData: (state) => {
      return state.trpGraphData
    }
  },
  actions: {
    async getVitals() {
      try {
        this.setErrors(null)
        this.loaderStatus = true;
        await axios
          .get("/patient_vitals")
          .then((response) => {
            this.loaderStatus = false;

            this.vitalsData = response.data.data.patient_vitals;
            this.latestVitalData = response.data.data.patient_vitals[0];
          })
          .catch((error) => {
            this.setErrors(error.response.data)
            this.loaderStatus = false;
          });
      } catch (error) {
        this.loaderStatus = false
      }
    },
    async getPatientTrpGraph() {
      try {
        this.setErrors(null)
        this.loaderStatus = true;
        await axios
          .get("/patientVitalsNew")
          .then((response) => {
            this.loaderStatus = false;

            this.trpGraphData = response.data.patient_vitals;
            
          })
          .catch((error) => {
            this.setErrors(error.response.data)
            this.loaderStatus = false;
          });
      } catch (error) {
        this.loaderStatus = false
      }
    },
  
    setErrors(error) {
      this.errors = error;
    }
  },
  
});
