
<template>
    <!-- <div class="row"> -->
            <searchheader @search="search" v-if="isAgent"/>
<div class="  row p-0 m-0">
<div class="col-sm-6 p-0 m-0 bg-white" >
    
            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>

            <div style="margin-bottom: 40px; overflow-x: scroll; max-height: 80vh; height: 80vh;" v-if="!getLoaderStatus" class="px-3">
                <table class="self-table wellness-table" >
                    <thead>
                        <tr>
                            <th>Bill Date</th>
                            <th>Bill No.</th>

                            <th v-if="isAgent">Patient Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tableRow" v-for="(trxn, index) in getAgentPatientList.invesgitionReports" :key="index" @click="handleFetchDetailOFBill(trxn.id)"   :style="{ 
    border: trxn.id == selectedReportId ? '2px dashed var(--foundation----primary---p100)' : ''
  }">
                            <td>{{ trxn.created_at??trxn.bill_date }}</td>
                            <td>{{ trxn.bill_no }}</td>
                            <td v-if="isAgent">{{ trxn.patientname }}</td>
                            <td class="text-center"><button @click="fetchPdf(trxn)"
                                    data-bs-toggle="modal" data-bs-target="#previewpatpdf" 
                                    class="btn btn-primary float-r">Preview</button></td>
                         
                        </tr>
                    </tbody>
                </table>
                <!-- <pagination v-model="getAgentTransactionList.current_page" :records="getAgentTransactionList.total" :per-page="getAgentTransactionList.per_page"  @paginate="searchPagination"  /> -->
            </div>
           
            <!--End of Wellness Table for Today Data -->
        </div>
<div class="col-sm-6  p-0 m-0 bg-white" >
   
    

            <div style="margin-bottom: 40px; overflow-x: auto; max-height: 80vh; height: 80vh;" v-if="!getLoaderStatus" class="px-3">
                <table class="self-table wellness-table" >
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Test Name</th>
                            <th>Specimen</th>
                            <th>Collected Date</th>
                            <th>Statue</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tableRow" v-for="(trxn, index) in getAgentPatientListDetail.testDetails" :key="index" >
                            <td>{{ trxn.code }}</td>
                            <td>{{ trxn.test_name }}</td>
                            <td>{{ trxn.specimen_name }}</td>
                            <td>{{ trxn.collected_at }}</td>
                            <td><span v-if="trxn.status == 0">Not Collected</span>
                                        <span v-if="trxn.status == 1">Collected</span>
                                        <span v-if="trxn.status == 2" class="received-status">Received</span>
                                        <span v-if="trxn.status == 3" class="resultentered-status">Result Entered</span>
                                        <span v-if="trxn.status == 3.5" class="resultentered-status">Provisional Verified</span>
                                        <span v-if="trxn.status == 4" class="verified-status">Verified</span>
                                        <span v-if="trxn.status == 7" class="dispatched-status">Dispatched</span></td>
                         
                        </tr>
                    </tbody>
                </table>
                <!-- <pagination v-model="getAgentTransactionList.current_page" :records="getAgentTransactionList.total" :per-page="getAgentTransactionList.per_page"  @paginate="searchPagination"  /> -->
            </div>
           
            <!--End of Wellness Table for Today Data -->
        </div>
        </div>
        <previewpdf :bill_no="getPreviewbillNo"></previewpdf> 
</template>

<script setup>
import '@/assets/css/pagination.css'
import router from '@/router/routes'
import { onMounted, ref, watch, onBeforeMount, onBeforeUnmount,computed } from 'vue';
import {useManageUser} from '@/core/modules/auth/user'
import { storeToRefs } from 'pinia'
import {useStoreAgent} from "@/core/modules/agent/agent";
import searchheader from '../../components/searchheader/searchheader.vue';
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import noRecordComp from '@/components/norecord'
import previewpdf from '@/components/modal/previewpdf';
import { isAgent } from '@/main';

// import Pagination from 'v-pagination-3';


const {getAgentDueAmount,getAgentData,getAgentPatientList,getLoaderStatus,getAgentPatientListDetail,getPreviewbillNo}=storeToRefs(useStoreAgent());



const {getUser} =  storeToRefs(useManageUser())


const agentStore=useStoreAgent()

const user = ref('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6FI5wnFojJPN6gWk1lF7mIAdzd3D8dhQaHVRd1snFHw&s')
const date = new Date();
let d = date.getHours();
let selectedReportId=ref("")

onMounted(async() => {
    
    if(isAgent){
        await agentStore.fetchAgentData();
    await agentStore.fetchAgentsTotalDueData();
         await agentStore.FetchAgentPatientList();
    }
   else await agentStore.FetchAgentPatientList({from_portal:1});
   
})
// function searchPagination(data){
//     console.log(data)
//     let params=JSON.parse(localStorage.getItem('searchParams'))
//     console.log(params)
//     agentStore.FetchAgentTransactionList({...params,page:data})


// }
function handleFetchDetailOFBill(id){
    selectedReportId.value=id
    agentStore.FetchAgentPatientListDetail(id)

}

async function fetchPdf(trxn){
    await agentStore.FetchAgentPatientListDetail(trxn.id,trxn.bill_no)
    await agentStore.FetchAgentPatientPdf({
        testList:trxn.tests,
        reportHeader:1,
        bill_no:trxn.bill_no,
        branch:1

    })


}


function search(params){
    localStorage.setItem('searchParams',JSON.stringify(params))
    agentStore.FetchAgentPatientList(params)

}







</script>

