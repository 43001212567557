<template>
    <modalComp :idmodal="'cancelwellness'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Cancel Wellness</h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <div class="strongpassword">Are you sure you want to cancel the wellness package <br> <span class="fw-bold">{{ package_name }}</span> ?
            </div>
        </template>
        <template v-slot:footer>
            <div class="col-6 m-0 p-0">
                <button type="button" class="btn border-top btn-lg w-100" data-bs-dismiss="modal">
                    No
                </button>
            </div>
            <div class="col-6 m-0 p-0">
                <button @click="cancel_wellness()" data-bs-dismiss="modal"  type="button" class="btn btn-primary btn-lg w-100">
                    Cancel
                </button>
            </div>
        </template>

    </modalComp>
</template>

<script setup>
import modalComp from '@/components/common/modal'
import { storeToRefs } from 'pinia'
import {useManageUser} from '@/core/modules/auth/user'
import { useSelfWellness } from "@/core/modules/self/wellness"
const {getUser} =  storeToRefs(useManageUser())
const store = useSelfWellness()
const props = defineProps({
    invoice: String,
    package_name: String,
    wellness_tab: String
})
function cancel_wellness(){
    store.cancel_wellness_package(props.invoice,getUser.value.patient_id, props.wellness_tab);
}
</script>