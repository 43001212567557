<template>
  <div class="row">
    <div class="col-md-12">
    
      <!-- <noRecordComp v-if="getInvestigationReportData.length < 1 && !getLoaderStatus" :title="'NO RECORD FOUND'">
      </noRecordComp> -->
      <!-- <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader> -->
    </div>
  </div>

  <patientReport />
  <!-- <div class="row" v-if="!getLoaderStatus && getInvestigationReportData.length > 0">
    <div class="col-sm-12"> -->
      <!-- <div class="row">
        <div class="col-md-3">
          <div class="bg-white rounded shadow-sm sticky_sidebar overflow-hidden">
            <div class="bg-white profile-details" style="    max-height: calc(100vh - 200px);overflow: auto;">
              <div v-for="(inv, index) in getInvestigationReportData" :key="index">
                <sideItemComp v-on:onactive="openPdf(inv.branch_id,inv.bill_no)" :is-active="inv.is_active" :title1="inv.created_at"  :title2="inv.bill_no"></sideItemComp>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 p-0">
          <div>
            <SpinnerDataLoader :classname="'text-primary'"  v-if="getPdfLoader"></SpinnerDataLoader>
            <iframe :src="getReportIframe" width="100%" style="height: calc(100vh - 250px);" v-if="!getPdfLoader"
              frameborder="0"></iframe>
          </div>

        </div>
      </div> -->
    <!-- </div>
  </div> -->
</template>


<script setup>
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue';
import { useSelfInvestigationReport } from "@/core/modules/self/investigationreport"
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import noRecordComp from '@/components/norecord'
import sideItemComp from '@/components/item';
import patientReport from '../agent/patientReport.vue';
const store = useSelfInvestigationReport()
const { getInvestigationReportData, getLoaderStatus,getPdfLoader,getReportIframe } = storeToRefs(useSelfInvestigationReport())

 onMounted(async () => {
  // await store.getInvestigationReport({from_portal});
})
function openPdf(branchId,billNum){
  store.setActiveVisit(branchId,billNum);
}
</script>