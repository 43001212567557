import axios from "axios";
import { defineStore } from "pinia";
import moment from "moment";

export const useSelfPrescription = defineStore("selfPrescription", {
  state: () => ({
    errors: null,
    loaderStatus: false,
    prescriptionData: "",
    hasdata: false,
    medPdf: "",
    pdfLoader: false,
    patientPrescriptionDashboard: ""
  }),
  getters: {
    getErrors: (state) => {
      return state.errors;
    },
    getLoaderStatus: (state) => {
      return state.loaderStatus;
    },
    getDateList: (state) => {
      return state.dateList;
    },
    getPrescriptionData: (state) => {
      return state.prescriptionData;
    },
    getHasdata: (state) => {
      return state.hasdata;
    },
    getMedPdf: (state) => {
      return state.medPdf;
    },
    getPdfLoader: (state) => state.pdfLoader,
    getPatientPrescriptionDashboard : (state) => {
      return state.patientPrescriptionDashboard
    }
  },
  actions: {
    async getPatientPrescriptionPdf(d) {
      try {
        this.pdfLoader = true;
        await axios
          .get("v2/pdf/prescription/" + d, {
            responseType: "blob",
          })
          .then((response) => {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const objectUrl = URL.createObjectURL(blob);
            this.medPdf = objectUrl;
            this.pdfLoader = false;
          });
      } catch (error) {
        this.pdfLoader = false;
      }
    },
    async getPatientPrescriptionDashboardData() {
      try {
        await axios
          .get("/v2/patient_prescription/")
          .then((response) => {
            this.patientPrescriptionDashboard = response.data.data;
          })
          .catch((error) => {
            console.log(error)
          })
      }
      catch (error) {
        console.log(error)
      }
    },
    async getPatientPrescription() {
      try {
        this.setErrors(null)
        this.dateList = [];
        this.hasdata = false;
        this.loaderStatus = true;
        await axios
          .get("/v2/patient_prescription/")
          .then((response) => {
            if (response.data.status === 200) {
              this.hasdata = true;
              // this.latestDateValue = response.data.data.medication;
              this.prescriptionData = response.data.data;
              this.getPatientPrescriptionPdf(this.prescriptionData[0].visit_id);
              this.momentDate();
            }
            this.loaderStatus = false;
          })
          .catch((error) => {
            this.setErrors(error.response.data)
            this.loaderStatus = false;
          });
      } catch (error) {}
    },
    setActiveVisit(visit_id) {
      this.prescriptionData.forEach((element) => {
        element.is_active = false;
        if (element.visit_id == visit_id) {
          element.is_active = true;
        }
      });
      this.getPatientPrescriptionPdf(visit_id);
    },
    setErrors(error) {
      this.errors = error;
    },
  },
  
  
});
