<template>
    <div class="py-0 osahan-profile row">
        <div class="col-md-3 px-3 mb-3">
            <div class="bg-white rounded shadow-sm sticky_sidebar overflow-hidden">

                <CardProfileComponent :patientid="getUser.patient_id"
                    :patientname="getUser.patient_name + ' (' + getUser.age_gender + ')'" :regdate="getUser.address"
                    :avatar="'@/assets/images/user.png'">
                </CardProfileComponent>
                <!-- profile-details -->

                <ItemSideNav></ItemSideNav>



            </div>
        </div>
        <div class="col-md-9 px-0 mb-3">
            <div class="rounded shadow-sm">
                <div class="osahan-privacy bg-white rounded p-4" v-if="route.meta.title">
                    <h4 class="fw-bold">{{ route.meta.title }}</h4>
                    <p class="text-muted">{{ route.meta.desc }}</p>
                    <router-view></router-view>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import CardProfileComponent from '@/components/card/profile'
import ItemSideNav from '@/components/sidenav/item'
import { useComponetSideNavItem } from '@/core/modules/components/sidenav/item'
import { useManageUser } from '@/core/modules/auth/user'
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router'
const route = useRoute()
const componentSideNavItem = useComponetSideNavItem();
const manageUser = useManageUser();
const { getSelectedMenu } = storeToRefs(useComponetSideNavItem())
const { getUser } = storeToRefs(useManageUser())
onMounted(() => {
    // manageUser.getUserDetails();
})
</script>