<template>
    <div id="edit_profile">
        <div>

            <div class=" form-group mb-3">
                <label class="pb-1">Patient Id</label>
                <input class="form-control" disabled :value="getUser.patient_id">
            </div>
            <div class="form-group mb-3">
                <label class="pb-1">Fullname</label>
                <input class="form-control" disabled :value="getUser.patient_name">
            </div>
            <div class=" form-group mb-3">
                <label class="pb-1">Mobile Number</label>
                <input class="form-control" disabled :value="getUser.phone_no">
            </div>
            <div class="form-group mb-3">
                <label class="pb-1">Age/Gender</label>
                <input class="form-control" disabled :value="getUser.age_gender">
            </div>
            <div class="form-group mb-3">
                <label class="pb-1">Email</label>
                <input type="email" class="form-control" disabled :value="getUser.email">
            </div>

            <div class="form-group mb-3">
                <label class="pb-1">Address</label>
                <input class="form-control" disabled :value="getUser.address">
            </div>


        </div>
        <div class="additional">
            <div class="change_password my-3">
                <router-link :to="{ name: 'page.manage.changepassword' }" class="p-3 border rounded bg-white btn d-flex align-items-center">Change
                    Password
                  
                    <font-awesome-icon icon="fa-solid fa-arrow-right" class="feather-arrow-right ms-auto"/>
                </router-link>
            </div>
            <div class="deactivate_account">
                <a @click="logout()" class="p-3 border rounded bg-white btn d-flex align-items-center">Logout
                    <font-awesome-icon icon="fa-solid fa-arrow-right" class="feather-arrow-right ms-auto"/> </a>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useManageUser } from '@/core/modules/auth/user'
import { storeToRefs } from 'pinia'
import router from '@/router/routes'
const manageUserStore = useManageUser()
const { getUser } = storeToRefs(useManageUser())


function logout(){
    manageUserStore.pugeAuth();
    router.push({name:'page.auth.login'})
}
</script>
