import { defineStore } from "pinia";
import { useApiHeader } from "@/core/services/apiheader";
import axios from "axios";
import { useRoute } from "vue-router";
// import { response } from "express";
export const useCommonStore = defineStore("commonstore", {
  state: () => ({
    downloadAppLink: [
      {
        title: "Android",
        link: "",
        // image: require("@/assets/images/play.png")
        image: "play.png"
      },
      {
        title: "IOS",
        link: "",
        // image: require("@/assets/images/appstore.png")
        image: "appstore.png"
      },
    ],
    imageLink : null,
    name: null,
    address: null,
    store_link:null,
    dowload_app_link:[ { "title": "Android", "link": "", "image": "play.png" }, { "title": "IOS", "link": "", "image": "appstore.png" } ],

  }),
  getters: {
    getRouteName: (state) => {
      const route = useRoute();
      const currentRouteName = route.name;
      return currentRouteName;
    },
    // getDownloadAppLink:(state)=> state.downloadAppLink,
    getDownloadAppLink:(state)=> state.dowload_app_link,
    getImageLink: (state) => state.imageLink,
    getName: (state) => state.name,
    getAddress: (state) => state.address,
    getStoreLink:state=>state.store_link
  },
  actions: {
    async fetchStoreLink(){
      await axios.get('/store_link').then(response=>{
        this.downloadAppLink[0].link =  response.data.data.playstore_link
        this.downloadAppLink[1].link =  response.data.data.appstore_link
        this.imageLink = response.data.data.client_image,
        this.name = response.data.data.client_name,
        this.address = response.data.data.address
        this.store_link=response.data.data.menu_items
        this.store_link.immigration_doctor_id=response.data.data.immigration_doctor_id
        this.dowload_app_link[0].link=response.data.data.playstore_link
        this.dowload_app_link[1].link=response.data.data.appstore_link
      })
    .catch(error=>{
      this.downloadAppLink[0].link =  null
        this.downloadAppLink[1].link =  null
        this.imageLink =null
        this.name = rnull
        this.address = null
        this.store_link=null
        this.dowload_app_link[0].link=null
        this.dowload_app_link[1].link=null
    })
  }}
});
