export const api={
    auth:{
        auth_login:"/login",

    },
    appointment:{
        fetchSpecialities:"/fetchSpecialities",
        fetchDoctor:"/fetchDoctor/{:id}",
        fetchDoctorDetail:"/fetchImmigrationDoctorDetails",
        fetchScheduleDate:"/fetchScheduleDates"
    },
    agent:{
        fetchAgentsTotalDue:"/agent/totalDue",
        fetchAgentData:"/agent/getAgentData",
        fetchAgentTransactionList:"/agent/transactionList",
        fetchAgentPatientList:"/agent/getpatientlist",
        fetchAgentPatientListDetail:"/agent/getPatientTestList/{:id}",
        fetchBillPdf:"/get/investigation/details/{:bill_no}",
        fetchBillPdfNew:"/v2/pdf/labreport",
        fetchAgentNews:"/agent/news/list",
    },
    patient:{
        fetchPatientListDetail:"/getPatientTestList/{:id}",
        fetchPatientBillList:"/get/bill-list"
    },
    payment:{
        fetchEpaymentVendorsConfig:"epayVendors"
    }
}