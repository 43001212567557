<template>
    <div>
        <div class="col-md-4 ">
            <div class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-hidden">
                <div class="osahan-cart-item-profile bg-white p-3">
                    <div class="d-flex flex-column">
                        <form action="">
                            <div v-if="getErrors ? (getErrors['errors'] && getErrors['status'] == 401 ? true : false) : false"
                                class="alert alert-danger" role="alert">
                                {{ getErrors['message'] }}
                            </div>
                            <div class="form-group mb-3" style="position: relative">
                                <label for="exampleFormControlInput1" class="small fw-bold pb-1">Current Password</label>
                                <input :type="!showOldPassword?'password':'text'" class="form-control mb-10" v-model="oldPassword"
                                    placeholder="Enter your current password">
                                <font-awesome-icon class="fo-text-color-primary-p300" @click="showCurrentPasswordFun()"
                                    :icon="!showOldPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"
                                    style="position: absolute;right: 10px; top: 34px; cursor: pointer;" />
                                <label for=""
                                    v-if="getErrors ? (getErrors['errors']['old_password'] ? true : false) : false"
                                    class="text-danger"> {{ getErrors ? (getErrors["errors"]['old_password'] ?
                                        getErrors["errors"]['old_password'][0] : "") : "" }}</label>
                            </div>
                            <div class="form-group mb-3" style="position: relative">
                                <label for="exampleFormControlInput1" class="small fw-bold pb-1">New Password</label>
                                <input :type="!showNewPassword?'password':'text'" class="form-control mb-10" v-model="newPassword"
                                    placeholder="Enter your new password">
                                <font-awesome-icon class="fo-text-color-primary-p300" @click="showPasswordFun()"
                                    :icon="!showNewPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"
                                    style="position: absolute;right: 10px; top: 34px; cursor: pointer;" />
                                <label for=""
                                    v-if="getErrors ? (getErrors['errors']['new_password'] ? true : false) : false"
                                    class="text-danger"> {{ getErrors ? (getErrors["errors"]['new_password'] ?
                                        getErrors["errors"]['new_password'][0] : "") : "" }}</label>
                            </div>
                            <div class="form-group mb-3" style="position: relative">
                                <label for="exampleFormControlInput1" class="small fw-bold pb-1">Confirm Password</label>
                                <input  :type="!showConfirmPassword?'password':'text'" class="form-control mb-10"
                                    v-model="confirmPassword" placeholder="Confirm Password">
                                <font-awesome-icon class="fo-text-color-primary-p300" @click="showConfirmPasswordFun()"
                                    :icon="!showConfirmPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"
                                    style="position: absolute;right: 10px; top: 34px; cursor: pointer;" />
                                    <label for=""
                                    v-if="getErrors ? (getErrors['errors']['confirm_password'] ? true : false) : false"
                                    class="text-danger"> {{ getErrors ? (getErrors["errors"]['confirm_password'] ?
                                        getErrors["errors"]['confirm_password'][0] : "") : "" }}</label>
                            </div>
                            <!-- <p style="font-size: 12px;" class="text-danger">Note: Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.</p> -->
                        </form>
                        <button class="btn btn-primary btn-lg" @click="onSubmit()" type="button" :disabled="getLodingBtn">
                            <span class="ml-2 text-lg">Change Password</span> <span v-if="getLodingBtn"
                                class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useAuthChangePassword, useAuthChangePasswordRef } from "@/core/modules/manage/changepassword"
import { onMounted } from 'vue';

const { getLodingBtn, getErrors } = storeToRefs(useAuthChangePassword())
const store = useAuthChangePassword()
const storeref = useAuthChangePasswordRef()
var {oldPassword, newPassword, confirmPassword,showOldPassword,showNewPassword,showConfirmPassword } = storeToRefs(storeref)

onMounted(()=>{
   oldPassword.value = '';
   newPassword.value = '';
   confirmPassword.value = '';
}) 

function onSubmit() {
    var password_credentials = {
        'old_password': oldPassword.value,
        'new_password': newPassword.value,
        'confirm_password':confirmPassword.value
    }
    store.submitChangePassword(password_credentials)
}


function showPasswordFun() {
    showNewPassword.value = (showNewPassword.value) ? false : true;
}
function showConfirmPasswordFun() {
    showConfirmPassword.value = (showConfirmPassword.value) ? false : true;
}
function showCurrentPasswordFun() {
    showOldPassword.value = (showOldPassword.value) ? false : true;
}
</script>