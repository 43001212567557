// import axios from "axios";
import { httpClient } from "@/core/services/http-client";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Modal } from 'bootstrap'
import { api } from "@/core/services/api";
import { useApiHeader } from "@/core/services/apiheader";
import axios from "axios";
export const useStoreAppointment = defineStore("storeAppointment", {
    state: () => ({
        errors: null,
        loaderStatus: false,
        fetechedSpecialitiesList:'',
        fetchedDocsInSpeciality:"",
        fetchedDocDetail:"",
        fetchedScheduleDates:'',
        selectedScheduleDate:"",
        selectedScheduleTimeSlotData:"",
        

    

    }),
    getters: {
        getErrors: (state) => {
            return state.errors;
        },
        getLoaderStatus: (state) => {
            return state.loaderStatus;
        },
        getfetechedSpecialitiesList:state=>{return state.fetechedSpecialitiesList},
        getFetchedDocsInSpecility:state=>{return state.fetchedDocsInSpeciality},
        getFetchedDocDetail:state=>{return state.fetchedDocDetail},
        getFetchedScheduleDates:state=>{return state.fetchedScheduleDates},
        getSelectedScheduleDate:state=>{return state.selectedScheduleDate},
        getSelectedScheduleTimeSlotData:state=>{return state.selectedScheduleTimeSlotData},

        
    },
    actions: {
        async fetchSpecialities(){
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                await axios
                    .get(api.appointment.fetchSpecialities)
                    .then((response) => {
                        this.loaderStatus = false;
                        this.fetechedSpecialitiesList = response.data.data;

                        
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.fetechedSpecialitiesList=''
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
                this.loaderStatus=false
            }
        },
        async  fetchDoctorsInSpecility(specilityId){
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                this.fetchedDocsInSpeciality=''
                await axios
                    .get(api.appointment.fetchDoctor.replace("{:id}",specilityId))
                    .then((response) => {
                        this.loaderStatus = false;
                        this.fetchedDocsInSpeciality = response.data.data;

                        
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.fetchedDocsInSpeciality=''
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
                this.loaderStatus=false
            }
        },
        async  fetchDocDetailById(docId){
            // note: no api currently
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                this.fetchedDocDetail=''
                await axios
                    .get(api.appointment.fetchDoctorDetail)
                    .then((response) => {
                        this.loaderStatus = false;
                        this.fetchedDocDetail = response.data.doctor;
                        this.fetchedDocDetail.doctor_time_table = response.data.doctor_time_table;

                        
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.fetchedDocDetail=''
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
                this.loaderStatus=false
                
            }
        },
        async  fetchScheduleDates(params){
            const apiHeader =  useApiHeader();
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                this.fetchedScheduleDates=''
                this.selectedScheduleDate=''
                this.selectedScheduleTimeSlotData=''
                apiHeader.setHeader();
                await axios
                    .get(api.appointment.fetchScheduleDate,{params:params})
                    .then((response) => {
                        this.loaderStatus = false;
                        this.fetchedDocDetail=response.data.doctor_details;
                        this.fetchedScheduleDates = response.data.data;
                        this.selectedScheduleDate=response.data?.data[0]?.date
                        this.selectedScheduleTimeSlotData=response.data?.data[0]?.data
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.fetchedScheduleDates=''
                this.selectedScheduleDate=''
                this.selectedScheduleTimeSlotData=''

                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
                this.loaderStatus=false
                
            }
        },
       async getScheduleTimeSlots(newDate,docId){
            this.selectedScheduleDate=newDate;
            let currentIndex=this.fetchedScheduleDates?.findIndex((element)=>element.date==newDate)
            if(currentIndex>-1){
                this.selectedScheduleTimeSlotData=this.fetchedScheduleDates[currentIndex].data
            }
            else{
                this.selectedScheduleTimeSlotData=''
            }


        },
 
        setErrors(error) {
            this.errors = error;

        }
    },
    persist:true  
});
