<template>
    <a style="cursor: pointer;" @click="onClick()"
        class="cursor-pointer d-flex w-100 align-items-center border-bottom px-3 py-4"
        :class="isActive ? 'fo-bg-color-success-s50 text-default fo-text-color-black' : ''" data-bs-toggle="modal"
        data-bs-target="#inviteModal">
        <div class="left me-3">
            <h6 v-if="title1" style="font-size: 14px;" class="fw-bold mb-1"> {{ title1 }} </h6>
            <h6  v-if="title2" style=" font-size: 12px;" class="fw-bold  mb-1 text-sm"> {{ title2 }}</h6>
            <h6 v-if="title3" style="font-size: 12px;" class="fw-bold  m-0">{{ title3 }}</h6>
        </div>
        <div class="right ms-auto">
            <span class="fw-bold m-0"><font-awesome-icon v-if="!isActive" icon="fa-solid fa-arrow-right"
                    class="h6 m-0" /></span>
        </div>
    </a>
</template>

<script setup>

const props = defineProps({
    title1: String,
    title2: String,
    title3: String,
    isActive: Boolean
})
const emit = defineEmits(['onactive'])
function onClick() {
    emit("onactive");
}
</script>