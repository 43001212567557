<template>
     <div class="osahan-text text-center">
            <div class="osahan-img px-5 pb-5">
             
            </div>
            <h2 class="text-primary mb-3 font-weight-light">Page not <b>found</b></h2>
            <p class="lead small mb-0">Oops! Looks like you followed a bad link.</p>
            <p class="mb-5">If you think this is a problem with us, please <a href="#">tell us</a>.</p>
            <a href="/" class="btn btn-primary">Go to home</a>
        </div>
</template>