import { defineStore } from "pinia";
import { useApiHeader } from "@/core/services/apiheader";
import axios from "axios";
import { useRoute } from "vue-router";
// import { response } from "express";
export const useComponetSideNavItem = defineStore("componentsidenavitem", {
  state: () => ({
    menus: [],
    dataLoading: true,
    svgLink: "",
    selectedMenu: null,
  }),
  getters: {
    getMenus: (state) => {
      return state.menus;
    },

    getDataLoading: (state) => {
      return state.dataLoading;
    },

    getSvgLink: (state) => {
      return state.svgLink;
    },
    getRouteName: (state) => {
      const route = useRoute();
      const currentRouteName = route.name;
      return currentRouteName;
    },
    getSelectedMenu: (state) => {
      return state.selectedMenu;
    },
  },
  actions: {
    setRouteName(){
        var routeName =  this.$route.name;
        // alert(routeName);
        // this.routeName = ;
    },

    setSeletedMenu(index) {
      //   this.selectedMenu = menu;
      //   if(index==null){
      //       window.localStorage.setItem("selected_menu_index",0)
      //   }else{
      //       window.localStorage.setItem("selected_menu_index",index)
      //   }
        this.$router.push({name:this.menus[index].route_name})
      // this.selectedMenu = this.menus[index];
    },
    async fetchMenus() {
      const apiHeader = useApiHeader();
      try {
        this.dataLoading = true;
        apiHeader.setHeader();
        await axios
          .get("/get-menu")
          .then((response) => {
            if (response.data.status == 200) {
              this.menus = response.data.data.data;
              this.svgLink = response.data.data.svg_url;
               


              this.setSeletedMenu(
                window.localStorage.getItem("selected_menu_index")
              );
            }
            this.dataLoading = false;
          })
          .catch((error) => {
            this.dataLoading = false;
          });
      } catch (error) {
        this.dataLoading = false;
      }
    },
  },
});
