// import axios from "axios";
import { httpClient } from "@/core/services/http-client";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Modal } from 'bootstrap'
import { api } from "@/core/services/api";
import { useApiHeader } from "@/core/services/apiheader";
import { isAgent } from "@/main";
import axios from "axios";
export const useStoreAgent = defineStore("storeAgent", {
    state: () => ({
        errors: null,
        loaderStatus: false,
        agentData:'',
        agentDueAmount:'',
        agentTransactionList:"",
        agentPatientList:'',
        agentPatientListDetail:'',
        medPdf :"",
        pdfLoader :false,
        previewbillNo:"",
        newsAndNotification:"",
       
    

    }),
    getters: {
        getErrors: (state) => {
            return state.errors;
        },
        getLoaderStatus: (state) => {
            return state.loaderStatus;
        },
        getAgentDueAmount:state=> state.agentDueAmount,
        getAgentData:state=> state.agentData,
        getAgentTransactionList:state=>state.agentTransactionList,
        getAgentPatientList:state=>state.agentPatientList,
        getAgentPatientListDetail:state=>state.agentPatientListDetail,
        getPreviewbillNo:state=>state.previewbillNo,
        getPdfUrl:state=>state.medPdf,
        getPdfLoader:state=>state.pdfLoader,
        getNewsAndNotification:state=>state.newsAndNotification
       

        
    },
    actions: {
        async fetchAgentsTotalDueData(){
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                await axios
                    .get(api.agent.fetchAgentsTotalDue)
                    .then((response) => {
                        this.loaderStatus = false;
                        this.agentDueAmount = response.data.due[0];

                        
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.agentDueAmount=''
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
                this.loaderStatus=false
            }
        },
        async fetchAgentData(){
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                await axios
                    .get(api.agent.fetchAgentData)
                    .then((response) => {
                        this.loaderStatus = false;
                        this.agentData = response.data.agentdetails[0];

                        
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.agentData=''
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
                this.loaderStatus=false
            }
        },
        async FetchAgentTransactionList(params){
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                await axios
                    .get(api.agent.fetchAgentTransactionList,{params:params})
                    .then((response) => {
                        this.loaderStatus = false;
                        this.agentTransactionList = response.data;

                        
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.agentTransactionList=''
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
                this.loaderStatus=false
            }
        },
        
        async FetchAgentPatientList(params){
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                this.agentPatientListDetail=''
                await axios
                    .get(isAgent?api.agent.fetchAgentPatientList:api.patient.fetchPatientBillList,{params:params})
                    .then((response) => {
                        this.loaderStatus = false;
                        
                        this.agentPatientList = response.data;                       
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.agentPatientList=''
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
                this.loaderStatus=false
            }
        },
        async fetchAgentNewsList(){
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                this.newsAndNotification=''
                await axios
                    .get(api.agent.fetchAgentNews)
                    .then((response) => {
                        this.loaderStatus = false;
                        this.newsAndNotification = response.data;                       
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.newsAndNotification=''
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
                this.loaderStatus=false
            }
        },
        
        async FetchAgentPatientListDetail(id,bill_no){
            try {
                this.setErrors(null);
                // this.loaderStatus = true;
                if(bill_no){
                this.previewbillNo=bill_no
                }
                await axios
                    .get(isAgent?api.agent.fetchAgentPatientListDetail.replace("{:id}",id):api.patient.fetchPatientListDetail.replace("{:id}",id))
                    .then((response) => {
                        // this.loaderStatus = false;
                        this.agentPatientListDetail = response.data;                       
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.agentPatientListDetail=''
                        // this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
                this.loaderStatus=false
            }
        },
        async FetchAgentPatientPdf(params){
            try {
                let count=0
                this.medPdf=''
                this.agentPatientListDetail?.testDetails?.forEach((e)=>{
                    if (e.status >= 4) {
                        count = count + 1;
                    }
                });
                if (count == 0) {
                    toast.error("Error!", "No Verified Report Found!", "error");
                }
                    else{
                this.pdfLoader = true;
                await axios
                  .get(api.agent.fetchBillPdfNew, {
                    responseType: "blob",
                    params:params
                  })
                  .then((response) => {
                    const blob = new Blob([response.data], { type: "application/pdf" });
                    const objectUrl = URL.createObjectURL(blob);
                    this.medPdf = objectUrl;
                    this.pdfLoader = false;
                  });
                }
              } catch (error) {
                this.pdfLoader = false;
                alert("e")
              }   

        },
        
 
        setErrors(error) {
            this.errors = error;

        }
    },
    persist:true  
});
