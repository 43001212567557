<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="rounded shadow-sm">
        <div class="osahan-privacy bg-white rounded shadow-sm p-4">
          <div class="row px-5 py-5 p-0">
            <bookwellness :wellness_plan_name="selectedWellnessPackage"></bookwellness>
            <!-- <paywellness :service_name></paywellness> -->
            <div class="row" style="justify-content: space-between; display: flex;">
              <div class="col-sm-2" style="cursor: pointer;">
                            <router-link :to="{ name: 'page.self.wellness' }">
                                <font-awesome-icon icon="fa-solid fa-arrow-left" style=" margin-right: 10px;" />
                                <span>Back</span>
                            </router-link>

                        </div>
            <div class="col-sm-8" style="text-align: center;">
              <h3>Wellness Plan</h3>
            </div>
            <div class="col-sm-2" style="position: relative">
            <input class="form-control" type="text" v-model="filter" placeholder="Search">
            <font-awesome-icon style="position: absolute; right: 20px; top: 12; color: var(--foundation----neutral---n100); font-size: var(--small---regular)" icon="fa-solid fa-search"/>
            </div>
            </div>
            <noRecordComp v-if="filteredWellnessPackage.length < 1 && !getLoaderStatus" :title="'NO RECORD FOUND'"></noRecordComp>
            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>
            <div class="col-sm-3" v-for="(well, index) in filteredWellnessPackage" :key="index">
              <wellnesscomponent v-if="!getLoaderStatus" @click="selectPackage(well.item_name, well.id)" :itemName="well.item_name">
              </wellnesscomponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useSelfWellness } from "@/core/modules/self/wellness";
import noRecordComp from '@/components/norecord'
import wellnesscomponent from "@/components/wellness/wellnesscomponent";
import SpinnerDataLoader from "@/components/loaders/spinnerloader";
import bookwellness from "@/components/modal/bookwellness";
import paywellness from "@/components/modal/paywellness"
var { getWellnessPackageData, getLoaderStatus } = storeToRefs(useSelfWellness());
const store = useSelfWellness();
const filter = ref('')
const selectedWellnessPackage = ref("");
onMounted(() => {
  store.getWellnessPackage();
});

function selectPackage(wellness_name, id) {
  selectedWellnessPackage.value = wellness_name;
  store.fetchWellnessPlanData(id);
}
const filteredWellnessPackage = computed(() => {
  let filteredData = getWellnessPackageData.value.length;
  if(filteredData > 0)
  return getWellnessPackageData.value.filter(vitals => {
    return vitals.item_name.toLowerCase().includes(filter.value.toLowerCase()) 
  })
  else
  return getWellnessPackageData

})
</script>
