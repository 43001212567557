<template>
    <div class="col-md-4 offset-md-4 mt-5 " style="overflow-y: auto;">
        <div class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-auto">
            <div class="osahan-cart-item-profile bg-white p-3">
                <div class="d-flex flex-column">
                    <h6 class="mb-3 fw-bold" v-if="!route.fullPath.includes('agent')">Login</h6>
                    <div v-else>
                        
                    <h6 class="mb-1 fw-bold" ><font-awesome-icon  icon="fa-solid fa-user" class="h6 m-0"/> <span class="pl-2"> Agent Login</span></h6>
                    <hr class="mb-2-1">
                </div>
                    <form action="">
                        <div v-if="getErrors ? (getErrors['errors'] && getErrors['status'] == 401 ? true : false) : false"
                            class="alert alert-danger" role="alert">
                            {{ getErrors['message'] }}
                        </div>

                        <div class="form-group mb-3">
                            <label class="small fw-bold pb-1">{{route.fullPath.includes("agent")? loginUsernameLabelAgent:loginUsernameLabel }}</label>
                            <input type="text" class="form-control" v-model="phoneNumber" placeholder="">
                            <label for="" v-if="getErrors ? (getErrors['errors']['id'] ? true : false) : false"
                                class="text-danger"> {{ getErrors ? (getErrors["errors"]['id'] ?
                                    getErrors["errors"]['id'][0] : "") : "" }}</label>
                        </div>

                        <div class="form-group mb-3" style="position: relative;">

                            <label class="small fw-bold pb-1">Password</label>
                            <input @keydown.enter="onSubmit()" style="padding-right: 30px;"
                                :type="!showPassword ? 'password' : 'text'" class="form-control" v-model="password"
                                placeholder="">
                            <label for="" v-if="getErrors ? (getErrors['errors']['password'] ? true : false) : false"
                                class="text-danger"> {{ getErrors ? (getErrors["errors"]['password'] ?
                                    getErrors["errors"]['password'][0] : "") : "" }}</label>

                            <font-awesome-icon v-if="password.length>0" class="fo-text-color-primary-p300" @click="showPasswordFun()"
                                :icon="!showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"
                                style="position: absolute;right: 10; top: 34px; cursor: pointer;" />
                        </div>
                    </form>
                    
                    <vue-turnstile site-key="1x00000000000000000000AA" v-model="token" />

                    <button class="btn btn-primary btn-lg mt-2" @click="onSubmit()" type="button" :disabled="getLoaderStatus">

                        <span class=" ml-2 text-lg">Login</span> <span v-if="getLoaderStatus"
                            class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                    </button>
                    <p class="mt-2 mb-3" >By Logging in, you agree to our <router-link :to="{ name: 'page.auth.terms' }"> Terms</router-link> and <router-link :to="{ name: 'page.auth.privacy' }"> Privacy Policy </router-link></p>
                    <div class="d-flex justify-content-center">
                    <router-link :to="{ name: 'page.auth.resetpassword' }" class="text-decoration-none me-2">
                        <p class="text-center hover-text">Forgot your password?</p>
                    </router-link>
                    <!-- <router-link  class="text-decoration-none"> -->
                        <!-- <button v-if=" bookpay == 0" @click="routeToSignUp" class="btn btn-primary mt-1 mb-1">Book Appointment</button> -->
                        <!-- <p class="text-center hover-text" style="color: var(--foundation----primary---p400);">Book Appointment</p> -->
                    <!-- </router-link> -->
                </div>
                    <div class="card p-0" style="background-color: var(--foundation----secondary---s0); font-size: 16px;" v-if="!isClinic">
                            <div class="px-3 py-3" style="background-color: #fff; border-radius: 0.375rem 0.375rem 0 0;">
                            <span  style="color: var(--foundation----secondary---s400); font-weight: 600;">Note: </span>
                        </div>
                        <div class="px-3 py-3">
                            
                            <span  style="color: var(--foundation----secondary---s400); line-height: 1;"> 
                              
                                Your username is your phone number and your password is your last name (all capital).
                                <br>
                                
                                तपाईको प्रयोगकर्ता नाम तपाईको फोन नम्बर हो र तपाईको पासवर्ड तपाईको थर (सबै अङ्ग्रेजी ठुलो अक्षर) हो । 
                                <br>
                           </span>

                        </div>
                        </div>
                      
            
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.spinner.spinner-right {
    padding-right: 3.5rem !important;
}
</style>

<script setup>
// import { mapState } from 'pinia'
import { storeToRefs } from 'pinia'
import { useAuthLogin, useAuthLoginRef } from "@/core/modules/auth/login"
import { onMounted, ref } from 'vue';
import { onBeforeMount } from 'vue';
import VueTurnstile from 'vue-turnstile';
import { usePayment } from "@/core/modules/payment";
import {isClinic,loginUsernameLabel,loginUsernameLabelAgent} from "../../mainConfig.js";

const token = ref('')
const store = useAuthLogin() // store
const storeref = useAuthLoginRef() //store ref
const { phoneNumber, password } = storeToRefs(storeref)
const { getErrors, getLoaderStatus } = storeToRefs(useAuthLogin())
const showPassword = ref(false)
const opd_id = ref('')
import { useRoute } from 'vue-router'
const bookpay = ref('')
const route = useRoute()
const paymentStore = usePayment()


onMounted(()=>{
    phoneNumber.value = '';
    password.value='';
    store.errorClear();
    opd_id.value = window.localStorage.getItem('opd_id');
    bookpay.value = window.localStorage.getItem('bookpay');
})
onBeforeMount(async () => {
 
    if (window.localStorage.getItem("phoneNumber")) {
        window.localStorage.removeItem("phoneNumber")
    }
})
function onSubmit() {
    var credentials = {
        'id': phoneNumber.value,
        'password': password.value,
        'is_agent': route.fullPath.includes("agent")
    }
    store.submitLogin(credentials);
}
function showPasswordFun() {
    showPassword.value = (showPassword.value) ? false : true;
}
function routeToSignUp() {
 paymentStore.checkSlotAvaliabilty(window.localStorage.getItem('schedule_id'))
}
</script>
