<template>
   <div class="bg-white ">
          
                <div class="d-flex justify-content-start align-items-center p-3 m-0 w-100">
<div class="px-3">
    <label class="mx-2">Date From:</label>
    <input type="date" v-model="dateFilter.fromdate"   class="date-input mx-2"  >
</div>
<div class="px-3">
    <label class="mx-2">Date To:</label>
 <input type="date" v-model="dateFilter.todate"     class="date-input mx-2" >
</div>
<!-- <div class="px-3">
    <label class="switch">
    <input type="checkbox" id="toggle" :checked="dateFilter.isBs" @change="handleDateChangeHandler">
    <span class="slider"></span>
  </label>

    </div>   -->
<div class="px-3">
    <label style="display: hidden;"></label>
<div class="search-button d-flex mx-5"  @click="search">
    <font-awesome-icon style="font-size: 22px" class="fo-text-color-white dashbaord_icon" :icon="'fa fa-solid fa-search'" /><span class="mx-0"></span>
</div>
</div>
 
 </div> 
</div>


</template>


<script setup>


import {ref,onMounted} from 'vue';
import {adToBs,bsToAd} from "@sbmdkl/nepali-date-converter";
// const props = defineProps({
//     fromdate: Number,
//     todate: String,
// });
const isAd=ref(true)
const dateFilter=ref({
    isBs:false,
    fromdate:new Date('01-01-2023').toISOString().split('T')[0],
    todate:new Date().toISOString().split('T')[0],
    search_key:'',
})
const emit = defineEmits(['search'])


onMounted(()=>{
 
    
})

function handleDateChangeHandler(){
    console.log(dateFilter.value.isBs); // Log current value

    // Change the value
    dateFilter.value.isBs = !dateFilter.value.isBs;
    if (dateFilter.value.isBs){
        dateFilter.value.fromdate= adToBs(dateFilter.value.fromdate)
        dateFilter.value.todate= adToBs(dateFilter.value.todate)
    }
    else{
        dateFilter.value.fromdate= bsToAd(dateFilter.value.fromdate)
        dateFilter.value.todate= bsToAd(dateFilter.value.todate)
    }


    console.log(dateFilter.value.isBs); // Log new value
}
function search(data) {
    
   console.log(data)
   const payload={...dateFilter.value}
   if(payload.isBs){
    payload.fromdate= bsToAd(payload.fromdate)
        payload.todate= bsToAd(payload.todate)
   }
   console.log(payload)
   emit("search", payload);



}
</script>
<style scoped>
  .date-input {
      border: 2px solid #ccc;
      border-radius: 5px;
      padding: 10px;
      background-color: white;
      font-size: 16px;
      min-width: 100px;
      max-width: 300px;
      width: 100%;
      outline: none;
      transition: border-color 0.3s;
    }
    .search-button {
      background-color: dodgerblue;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s;
    }

    .search-button:hover {
      background-color: royalblue;
    }
    .switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2196F3;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(70px); /* Adjusted position for width 100px */
}

.slider:after {
  content: "AD";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50px;
}

input:checked + .slider:after {
  content: "BS";
  left: calc(100% - 50px);
}
/* Default styles */
.checkbox-container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.checkbox-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.checkbox-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

/* When checked */
.checkbox-container input:checked + .checkbox-slider {
  background-color: #2196F3;
}

.checkbox-container input:focus + .checkbox-slider {
  box-shadow: 0 0 1px #2196F3;
}

.checkbox-container input:checked + .checkbox-slider:before {
  transform: translateX(26px);
}


</style>