import axios from "axios";
import { toast } from "vue3-toastify";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useAuthChangePasswordRef = defineStore(
  "authchangepasswordref",
  () => {
    const oldPassword = ref();
    const newPassword = ref();
    const confirmPassword = ref();
    const showOldPassword = ref(false);
    const showNewPassword = ref(false);
    const showConfirmPassword = ref(false);

    return { oldPassword, newPassword, confirmPassword,showOldPassword,showNewPassword,showConfirmPassword };
  }
);

export const useAuthChangePassword = defineStore("authchangepassword", {
  state: () => ({
    errors: null,
    lodingBtn: false,
  }),
  getters: {
    getErrors: (state) => {
      return state.errors;
    },
    getLodingBtn: (state) => {
      return state.lodingBtn;
    },
  },
  actions: {
    clearForm() {
      // this.errors = null;
      this.setErrors(null)
    },
    async submitChangePassword(password_credentials) {
      try {
        this.setErrors(null)
        this.lodingBtn = true;
        await axios
          .post("/change_password", password_credentials)
          .then((response) => {
            if (response.data.status == 200) {
              toast("Password has been successfully updated !", {
                autoClose: 1000,
              });
              this.clearForm();
              this.$router.push({ name: "page.manage.profile" });
            }
            this.lodingBtn = false;
          })
          .catch((error) => {
            this.setErrors(error.response.data)
            this.lodingBtn = false;
          });
      } catch (error) {
        this.lodingBtn = false;
      }
    },
    setErrors(error) {
      this.errors = error;
      },
  },
});
