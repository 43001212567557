<template>
    
         <div class="col-sm-12" v-if="getPurchaseHistoryData.length > 0">
            <span style="color:var(--foundation----primary---p200)" class="fw-700"></span>
            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>

            <!-- Wellness Table for Today Data -->
            <div style="margin-bottom: 20px; overflow-x: scroll;" v-if="!getLoaderStatus && getPurchaseHistoryData.length > 0">
                <table class="self-table wellness-table" >
                    <thead>
                        <tr>
                            <th>Bill No</th>
                            <th>Bill Date</th>
                            <th>Service Name</th>
                            <th>Amount</th>
                            <th>Receipt No</th>
                            <!-- <th>Payment Name</th>
                            <th>Reference No</th> -->
                            <!-- <th ></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tableRow" v-for="(wellness, index) in displayedTodayRecord" :key="index">
                            <td>{{ wellness.id }}</td>
                            <td>{{ wellness.bill_date }}</td>
                            <td>{{ wellness.item_name }}</td>
                            <td>{{ wellness.amount }}</td>
                            <td>{{ wellness.receipt_no }}</td>
                            <!-- <td>Rs. {{ wellness.amount }}</td> -->
                            <!-- <td><button @click="invoice_number_fun(wellness.invoice_no, wellness.name)"
                                    data-bs-toggle="modal" data-bs-target="#cancelwellness"
                                    class="btn btn-primary float-r">Cancel</button></td> -->
                        </tr>
                    </tbody>
                </table>
                <pagination v-model="todayPage" :records="getPurchaseHistoryData.length" :per-page="perPage" @paginate="myCallback" />
            </div>
            <!--End of Wellness Table for Today Data -->
        </div>
</template>
<script setup>
import { onMounted,ref,computed } from 'vue';
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import { storeToRefs } from 'pinia'
import { useSelfPurchaseHistory } from "@/core/modules/self/purchasehistory"
const store = useSelfPurchaseHistory()
const {getPurchaseHistoryData,getLoaderStatus}=storeToRefs(useSelfPurchaseHistory())
const todayPage = ref(1)
const perPage = ref(20)
onMounted(async() => {
    await store.getPurchaseHistory()
})
const displayedTodayRecord = computed(() => {
    const startIndex = perPage.value * (todayPage.value - 1);
    const endIndex = startIndex + perPage.value;
    return getPurchaseHistoryData.value.slice(startIndex, endIndex)
})
</script>