<template>
    <header class="section-header">
        <section class="header-main shadow-sm bg-white">
            <div class="container-fluid">
              
                <div class="row align-items-center">
                    <div class="col-1">
                        <a @click="goTo()" class="brand-wrap" style="cursor: pointer;">
                            <img class="img-fluid" :src="getImage()">
                        </a>
                        <!-- brand-wrap.// -->
                    </div>
                    <div @click="goTo()" class="col-5 hospital_name_address_info " style="cursor: pointer;">
                        <span>{{getName}}</span>
                        <!-- NOTE: STATIC KEPT FOR NOW; NEW CHANGES -->
                        <!-- <span>Province Hospital</span> -->
                        <br>
                        <span>{{getAddress}}</span>
                        <!-- <span>Birendranagar, Surkhet, Nepal</span> -->
                    </div>
                    <!-- col.// -->
                    <div class="col-6">
                        <div class="d-flex align-items-center justify-content-end pe-5">
                          
                            
                              
                            <!-- <router-link :class="route.path == '/' || route.path == '/appointment' || route.path.includes('/appointment/doctor/')? 'd-none' : '' " :to="{ name: 'page.appointment' }" class="widget-header me-4 text-white btn bg-primary m-none">
                                <div class="icon d-flex align-items-center">
                                    <i class="feather-disc h6 me-2 mb-0"></i> <span>Hospital Appointment</span>
                                </div>
                            </router-link> -->
                            <div  v-if="!isAgent">
                            <div class="dropdown me-4 m-none" v-for="(storelink,i) in getStoreLink" :key="i">
                                <a href="#" class="dropdown-toggle text-dark py-3 d-block font-size-lg" id="dropdownAppointment"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="storelink.submenu?.length>0">
                                   {{ storelink.name }}
                                </a>
                                <!-- be needs to handle routes to not do this: ps: they are lazy -->
                                <div v-if="storelink.name?.toLowerCase()=='appointment'">

                                <div class="dropdown-menu dropdown-menu-right"
>

                                    <a class="dropdown-item" target="_blank" v-for="(link,index) in storelink.submenu" :key="index" :href="link.link">
                                         <router-link style ="cursor: pointer;" class="d-flex justify-content-left align-items-center" :to="link.is_batch?'/doctor/slot/'+getStoreLink.immigration_doctor_id+'/'+link.country_id:'/opdDept/'+link.country_id
">
                                            {{ link.name }}
                                         </router-link>
                                        </a>
                                    
                                </div>
                            </div>
                                <div v-if="storelink.name?.toLowerCase()=='wellness'">
                                 

                                <!-- <div class="dropdown-menu dropdown-menu-right">

                                    <a class="dropdown-item" target="_blank" v-for="(link,index) in [{title:'Immigration Medical Examination (Australia) ',to:'/appointment'},{title:'Immigration Medical Examination (New Zealand) ',to:'/appointment'},{title:'Immigration Medical Examination (United Kingdom) ',to:'/appointment'},{title:'Hospital Appointment ',to:'/opdDept'}]" :key="index" :href="link.link">
                                         <router-link style ="cursor: pointer;" class="d-flex justify-content-left align-items-center" :to="link.to">
                                            {{ link.title }}
                                         </router-link>
                                        </a>
                                    
                                </div> -->
                            </div>
                            </div>
                        </div>
                        
                            <div class="dropdown me-4 m-none"  v-if="!isAgent"
>
                                <a href="#" class="dropdown-toggle text-dark py-3 d-block font-size-lg" id="dropdownDownload"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                   Download App
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">

                                    <a class="dropdown-item" target="_blank" v-for="(link,index) in getDownloadAppLink" :key="index" :href="link.link">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <img style="max-width: 150px; cursor: pointer; "   @click="selectPayment" :src="require(`@/assets/images/${link.image}`)" alt="">
                                            <!-- <span>{{link.title}}</span> -->

                                        </div>
                                        </a>
                                    
                                </div>
                            </div>
                             <router-link :to="{name:'page.auth.login'}"  class="widget-header me-4 text-dark sign_in" v-if="!getIsAuthorized && route.path != '/login'">
                                <div class="icon d-flex align-items-center font-size-lg">
                                    <i class="feather-user h6 me-2 mb-0"></i> <span>Sign in</span>
                                </div>
                            </router-link>
                            <!-- my account -->
                            <div class="dropdown me-4 " v-if="getIsAuthorized">
                                <a href="#" class="dropdown-toggle text-dark py-3 d-block user_info_dropdown" id="dropdownMenuButton"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                    <img alt="#" src="@/assets/images/user.png" class="img-fluid rounded-circle header-user me-2"> <span class="m-none font-size-sm">Hi
                                        {{getUser.patient_name}}</span>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" >
                                    <span v-if="!isAgent">
                                    <router-link :to="{name:'page.manage.profile'}" class="dropdown-item" >My account</router-link 
></span>
                                    <!-- <router-link :to="{name:'page.contact-us'}" class="dropdown-item" >Contact Us</router-link> -->
                                    <!-- <router-link :to="{name:'page.disclaimerandprivacy'}" class="dropdown-item" >Disclaimer and Privacy</router-link> -->
                                    <!-- <a class="dropdown-item" href=@/pages/contact-us.vue>Contant us</a> -->
                                    <!-- <a class="dropdown-item" href="terms.html">Disclaimer and Privacy</a> -->
                                    <!-- <a class="dropdown-item" href="terms.html">Terms and Condition</a> -->
                                    <a class="dropdown-item" @click="logout()">Logout</a>
                                </div>
                            </div>
                        
                            <!-- <div class="toggle b-none" >
                                <font-awesome-icon icon="fa-solid fa-burger"/>
                            </div> -->
                        </div>
                        <!-- widgets-wrap.// -->
                    </div>
                    <!-- col.// -->
                </div>
                <!-- row.// -->
            </div>
            <!-- container.// -->
        </section>
        <!-- header-main .// -->
    </header>
</template>

<script setup>
import {useManageUser} from '@/core/modules/auth/user'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router/routes'
import routerNew from '@/router/routesAgent'
import {useCommonStore} from '@/core/modules/common'
import {isAgent} from '../main'
const route  =  useRoute()
const manageUserStore  = useManageUser()
const manageCommonStore = useCommonStore()
const {getUser,getIsAuthorized}  =  storeToRefs(useManageUser())
const {getDownloadAppLink, getImageLink, getName, getAddress,getStoreLink} = storeToRefs(useCommonStore())
const paramsId = ref(null)
onMounted(async ()=>{
    await manageCommonStore.fetchStoreLink();
    await manageUserStore.getUserDetails();
    if(route.params.id) {
        paramsId.value = route.params.id
    }
})

function logout(){
    manageUserStore.pugeAuth();
    router.push({name:'page.auth.login'})
}

function getImage(){
    return getImageLink.value
}
function goTo(){
    if(getIsAuthorized.value) {
        if(isAgent){

            routerNew.push({name:'page.agent.dashboard'})
        }
        else{
    
        router.push({name:'page.dashboard'})
        }
    }
    else {
        if(isAgent){

            routerNew.push({name:'page.auth.login.agent'})
}
else{
        router.push({name: 'page.landing'})
    }
}
}
</script>