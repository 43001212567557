<template>

    <div class="row">
        <div class="col-md-12">
            <noRecordComp v-if="!getHasdata && !getLoaderStatus" :title="'NO RECORD FOUND'"></noRecordComp>
            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>
        </div>
    </div>
    <div class="row" v-if="!getLoaderStatus && getHasdata">
        <div class="col-sm-12">
            <div>
              
                <div class="row">
                    <div class="col-md-3">
                        <div class="bg-white rounded shadow-sm sticky_sidebar overflow-hidden">
                            <div class="bg-white profile-details" style="    max-height: calc(100vh - 200px);overflow: auto;">
                                <div v-for="(meds, index) in getPrescriptionData" :key="index">
                                    <sideItemComp v-on:onactive="openPdf(meds.visit_id)" :is-active="meds.is_active" :title3="'Visit Number: ' + meds.visit_id" :title1="meds.date + ' ' + meds.time" :title2="'Prescribed By: '+meds.doctor_name"></sideItemComp>
                                </div>
                                <div><!--v-if--></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 p-0">
                        <div>
                            <SpinnerDataLoader :classname="'text-primary'" v-if="getPdfLoader"></SpinnerDataLoader>
                            <iframe :src="getMedPdf" width="100%" style="height: calc(100vh - 250px);" v-if="!getPdfLoader"
                                frameborder="0"></iframe>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>

import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue';
import { useSelfPrescription } from "@/core/modules/self/prescriptions"
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import noRecordComp from '@/components/norecord'
import sideItemComp from '@/components/item'
const store = useSelfPrescription()

const { getLoaderStatus, getPrescriptionData, getHasdata, getMedPdf, getPdfLoader } = storeToRefs(useSelfPrescription())

onMounted(() => {
    store.getPatientPrescription();
})
function openPdf(visit_id) {
  
    store.setActiveVisit(visit_id);
}
</script>
