<template>
    <div class="col-md-4 offset-md-4 mt-3">
        <div class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-hidden">
            <div class="osahan-cart-item-profile bg-white p-3">
                <div class="d-flex flex-column">
                    <h6 class="mb-3 fw-bold">Booking Appointment Form</h6>
                    <form action="">
                        <div class="form-group mb-3">
                            <div class="row">
                                <h6 class="fw-bold" style="color: var(--foundation----primary---p400);">Basic Details</h6>
                                <div class="col-sm-6">
                                    <label class="small fw-bold pb-1">First Name</label>
                                    <input v-model='payloadData.fname' required type="text" class="form-control" placeholder="First Name" id="firstname" >
                                    <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataErrorState.fname">This field is required</div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="small fw-bold pb-1">Middle Name</label>
                            <input type="text" class="form-control" placeholder="Middle Name" v-model="payloadData.mname">
                                </div>
                                <div class="col-sm-6">
                                    <label class="small fw-bold pb-1">Last Name</label>
                            <input required type="text" class="form-control" placeholder="Last Name" id="lastname" v-model="payloadData.lname">
                            <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataErrorState.lname">This field is required</div>

                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3" style="position: relative;">
                            <div class="row">
                                <h6 class="fw-bold" style="color: var(--foundation----primary---p400);">Address Details</h6>
                                <div class="col-sm-6">
                                    <label class="small fw-bold pb-1">Country</label>
                                    <select disabled id="country" v-model="payloadData.country" class="form-control">
                                        <option :value="index" v-for="(country,index) in getCountries.country" :key=" index">{{country}}</option>
                                    </select>
                            <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataErrorState.country">This field is required</div>

                                </div>
                                <div class="col-sm-6">
                                    <label class="small fw-bold pb-1">Province</label>
                                    <select @change="fetchDistricts" id="province" v-model="payloadData.province_id" class="form-control">
                                        <option :value="index" v-for="(prov,index) in getProvinces.provinces" :key=" index">{{prov}}</option>
                                    </select>
                            <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataErrorState.province_id">This field is required</div>

                                </div>
                                <div class="col-sm-6">
                                    <label class="small fw-bold pb-1">District</label>
                                    <select @change="fetchMunicipalities" id="district" v-model="payloadData.province_district_id" class="form-control">
                                        <option disabled value="0">Select District</option>
                                        <option :value="index" v-for="(dist,index) in getProvinceDistrict.districts" :key=" index">{{dist}}</option>

                                    </select>
                            <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataErrorState.province_district_id">This field is required</div>

                                </div>
                                <div class="col-sm-6">
                                    <label class="small fw-bold pb-1">Municipality</label>
                                    <select id="municipality" @change="selectMuniciplaity" v-model="payloadData.municipality_id" class="form-control">
                                        <option value="0"  selected disabled>Select Municipality</option>
                                        <option :value="index" v-for="(municipality,index) in getMunicipality.municipality" :key=" index">{{municipality}}</option>
                                    </select>
                            <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataErrorState.municipality_id">This field is required</div>

                                </div>
                                <div class="col-sm-6">
                                    <label class="small fw-bold pb-1">Ward</label>
                                    <input required type="text" class="form-control" placeholder="Ward" id="ward" v-model="payloadData.ward">
                                </div>
                            </div>
                            
                        </div>
                        <div class="form-group mb-3" style="position: relative;">

                            <div class="row">
                                <h6 class="fw-bold" style="color: var(--foundation----primary---p400);">Other Details</h6>
                                <div class="col-sm-6">
                                    <label class="small fw-bold pb-1">Date of Birth (AD)</label>
                                    <input required type="date" @input="calculateAge" class="form-control" id="dob" placeholder="Date" v-model="payloadData.dob">
                            <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataErrorState.dob">This field is required</div>

                                </div>
                                <div class="col-sm-6 " >
                                    <div class="d-flex justify-content-around" style="width: 90%;">
                                        <div>
                                    <label class="small fw-bold pb-1">Age</label>
                                    <input required type="text" class="form-control" @input="calculateDate" @change="calculateDate" placeholder="Age"  id="age" v-model="payloadData.age" >
                            <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataErrorState.age">This field is required</div>
                        </div>
                        <div>
                                       <label class="small fw-bold pb-1" style="visibility: hidden;">Age</label>
                                    <select  id="age_type" v-model="payloadData.age_type" class="form-control" style="width: 150%;" @change="calculateDate" >
                                        <option  value="Y">Years</option>
                                        <option  value="M">Months</option>
                                        <option  value="D">Days</option>

                                    </select>

                        </div>
                        </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="small fw-bold pb-1">Gender</label>
                                    <select id="gender" v-model="payloadData.gender" class="form-control">
                                        <option value="" disabled selected>Select Gender</option>
                                        <option v-for="(g,index) in formData.gender" :key="index"  :value="g.category">{{g.category}}</option>
                                    </select>
                                    <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataErrorState.gender">This field is required</div>

                                </div>
                                <div class="col-sm-6">
                                    <label class="small fw-bold pb-1">Contact No.</label>
                            <input required type="number" id="contactNo" class="form-control" placeholder="Contact No." v-model="payloadData.mobileNo">
                            <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataErrorState.mobileNo">This field is required</div>
                            <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataNumberErrorState.mobileNo">This mobile number must be 10 digits.</div>

                                </div>

                                <div class="col-sm-6" style="position: relative;">
                                    <label class="small fw-bold pb-1">Ethnicity</label>
                                    <select id="ethnicitycode" v-model="payloadData.ethinicity_code" class="form-control">
                                        <option value="0" disabled>Select Ethnicity</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                            <div class="error-message-class text-danger" style="font-size: 12px;" v-if="payloadDataErrorState.ethinicity_code">This field is required</div>

                                    <font-awesome-icon data-bs-toggle="modal" data-bs-target="#castecode" style="position: absolute; right: 20px; top: 33px; cursor: pointer;" icon="fa-solid fa-info-circle"/>
                                </div>
                            </div>
                            
                        </div>
                    </form>
                    
                    <vue-turnstile class="my-3" site-key="1x00000000000000000000AA" v-model="token" />
                    <label for="" v-if="getErrors ? (getErrors['message'] ? true : false) : false"
                                class="text-danger" style="font-size: 12px;"> {{ getErrors ? (getErrors["message"] ?
                                    getErrors['message'] : "") : "" }}</label>
                    <button class="btn btn-primary btn-lg mt-2" @click="onSubmit()" type="button" :disabled="getLoaderStatus">

                        <span class=" ml-2 text-lg">Proceed To Book</span> <span v-if="getLoaderStatus"
                            class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                    </button>
                        <p class="text-center text-decoration-none mt-3">Already Signed Up? <span style="cursor: pointer; color: var(--foundation----primary---p400);" @click="routeTosignIn">Sign In</span></p>
                        </div>
            </div>
        </div>
      
    </div>
    <castecode></castecode>
</template>
<style lang="scss" scoped>
.spinner.spinner-right {
    padding-right: 3.5rem !important;
}

</style>

<script setup>
// import { mapState } from 'pinia'
import { storeToRefs } from 'pinia'
import { useSelfAppointment } from "@/core/modules/self/appointment"
import { onMounted, ref } from 'vue';
import { onBeforeMount } from 'vue';
import VueTurnstile from 'vue-turnstile';
import router from '@/router/routes'
import castecode from "@/components/modal/castecode"
import { usePayment } from "@/core/modules/payment"
import { useSelfAddress } from "@/core/modules/address"
import moment from 'moment';
const addressStore = useSelfAddress()
const token = ref('')
const store = useSelfAppointment() // store
const paymentStore = usePayment()
const { getProvinces, getProvinceDistrict, getMunicipality, getCountries } = storeToRefs(useSelfAddress());
const { getBookingId } = storeToRefs(usePayment())
const payloadData=ref({
   
        fname : '',
        mname : '',
        lname : '',
        dob : '',
        age : '',
        age_type:'Y',
        gender : '',
        mobileNo : '',
        country: getCountries.value.default_country,
        province_id: getProvinces.value.default_province??0,
        province_district_id: getProvinceDistrict.value.default_province??0,
        municipality_id: 0,
        ward: '',
        // address: formData.value.address.province + ', ' + formData.value.address.district + ', ' + formData.value.address.municipality + ', ' + formData.value.address.ward,
        ethinicity_code :1
})
const payloadDataErrorState=ref({

})
const payloadDataNumberErrorState = ref({})

const formData = ref({
    gender: [
    {
        category: 'Male'
    },
    {
        category: 'Female'
    },
    {
        category: 'Others'
    },
    ]
})

const { getErrors, getLoaderStatus } = storeToRefs(useSelfAppointment())


onMounted(async() => {
    var body = {
            schedule_list_id: window.localStorage.getItem('schedule_id')
        }
    await addressStore.getProvincesData();
    await addressStore.getProvinceDistrictData(getProvinces.value.default_province);
    await addressStore.getMunicipalityData(getProvinceDistrict.value.default_province);
    await addressStore.getCountriesData();
    await paymentStore.checkSlotAvaliabilty(body.schedule_list_id)
    payloadData.value.country= getCountries.value.default_country;
    payloadData.value.province_id= getProvinces.value.default_province??0;
    payloadData.value.province_district_id= getProvinceDistrict.value.default_province??0

    // window.addEventListener("beforeunload", async () =>  await addressStore.getProvincesData())

})

window.addEventListener.on

function onSubmit() {
    payloadDataErrorState.value={}
    payloadDataNumberErrorState.value = {}
    for(let key in payloadData.value){
        if(!(key=='ward'|| key=='mname')){
        if(!payloadData.value[key]){
            
            payloadDataErrorState.value[key]='This field is required'
        }
        if(key == 'mobileNo'){
            if(payloadData.value[key].toString().length != 10 && payloadData.value[key].toString().length != 0 ){
                payloadDataNumberErrorState.value[key]='The mobile number must be 10'
            }
        }
    }
    }
if(Object.keys(payloadDataErrorState.value).length != 0 || Object.keys(payloadDataNumberErrorState.value).length != 0){}

 
    else {
        var body = {
        ...payloadData.value,
        schedule_list_id : parseInt(window.localStorage.getItem('schedule_id')),
        booking_id : getBookingId.value,
        address: getProvinces.value.provinces[payloadData.value.province_id] + ', ' + getProvinceDistrict.value.districts[payloadData.value.province_district_id] + ', ' + getMunicipality.value.municipality[payloadData.value.municipality_id] + ', ' + payloadData.value.ward,
    }
    paymentStore.savePatient(body,parseInt(window.localStorage.getItem('schedule_id')))
    }
}
function calculateAge() {
    let ageis=moment().diff(moment(new Date(payloadData.value.dob), 'YYYY-MM-DD'), 'years')
    if(ageis>0){
        payloadData.value.age=Math.floor(ageis)
        payloadData.value.age_type='Y'
    }
    else{
        let monthis=moment().diff(moment(new Date(payloadData.value.dob), 'YYYY-MM-DD'), 'months')
    if(monthis>0){
        payloadData.value.age=Math.floor(monthis)
        payloadData.value.age_type='M'
    }
    else{
        let dayis=moment().diff(moment(new Date(payloadData.value.dob), 'YYYY-MM-DD'), 'days',true)
        payloadData.value.age=Math.floor(dayis)
        payloadData.value.age_type='D'
    }
    }
}
function calculateDate(){
    let subtractFrom=  payloadData.value.age_type==='Y'?'years':payloadData.value.age_type==='M'?"months":"days"

    payloadData.value.dob=moment().subtract(payloadData.value.age,subtractFrom).format('YYYY-MM-DD')
}
function showPasswordFun() {
    showPassword.value = (showPassword.value) ? false : true;
}
function routeTosignIn(){
    router.push({name: 'page.auth.login'})
}
function fetchDistricts(event) {
    addressStore.getProvinceDistrictData(event.target.value);
    payloadData.value.province_district_id = 0;
    payloadData.value.municipality_id = 0;

}
function fetchMunicipalities(event){
    addressStore.getMunicipalityData(event.target.value);
    payloadData.value.municipality_id = 0;

}
function selectMuniciplaity(event) {
    payloadData.value.municipality_id = event.target.value;
}
</script>
