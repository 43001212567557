
<template>
    <div :class="getRouteName == 'page.manage.selectaccount' ? 'col-md-6 offset-md-3' : 'col-md-12'">
        <div class="osahan-cart-item mb-3 rounded  overflow-hidden"
            :class="getRouteName == 'page.manage.selectaccount' ? 'shadow-sm bg-white' : ''">
            <div class="osahan-cart-item-profile bg-white p-3">
                <div class="d-flex flex-column">
                    <h6 class="mb-3 fw-bold" v-if="getRouteName == 'page.manage.selectaccount'"> <font-awesome-icon
                            icon="fa-solid fa-user-group" class="mx-2 fo-text-color-primary-p300" />Select a user</h6>
                    <SpinnerDataLoader :classname="'text-primary'" v-if="getDataLoader"></SpinnerDataLoader>
                    <div v-if="!getDataLoader" style="max-height: calc(100vh - 350px);overflow: auto;">
                        <CardProfileComponent v-for="(account, index) in getAccounts" :key="index"
                            :patientid="account.patient_id" :patientname="account.patient_name" :regdate="account.reg_date"
                            :avatar="'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6FI5wnFojJPN6gWk1lF7mIAdzd3D8dhQaHVRd1snFHw&s'"
                            :token="account.token" :bg="true">
                        </CardProfileComponent>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary rounded-0 w-100 btn-lg" v-if="!getDataLoader" @click="Continue()"
                :disabled="getSelectToken == ''"> {{ getRouteName == 'page.manage.selectaccount' ? 'Continue' : 'Selected' }}

               
            </button>
        </div>
    </div>
</template>



<script setup>
import CardProfileComponent from '@/components/card/profile'
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import { useComponetSideNavItem } from '@/core/modules/components/sidenav/item'
import { useCommonStore } from '@/core/modules/common'
import { useManageUser } from '@/core/modules/auth/user'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useSelectAccount } from "@/core/modules/manage/selectaccount"
import { onMounted } from 'vue';
import router from '@/router/routes'
const store = useSelectAccount() // store
const route = useRoute();

const commonStore = useCommonStore();
const componentSideNavItemStore = useComponetSideNavItem();
const manageUserStore = useManageUser();

const { getErrors, getAccounts, getDataLoader, getSelectToken } = storeToRefs(useSelectAccount())
const { getRouteName } = storeToRefs(useCommonStore())
onMounted(() => {
    store.fetchAccounts();
})

function Continue() {

    if (getSelectToken.value != '') {
        store.submitContinue(getSelectToken.value);
        manageUserStore.getUserDetails();
        router.push({ name: 'page.dashboard' });
    }
}

</script>