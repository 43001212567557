<!-- eslint-disable vue/require-v-for-key -->
<template>
    <!-- <div class="row"> -->
            <div style="overflow: scroll;" >
                <div class="osahan-privacy bg-white rounded shadow-sm p-4 " >
                    <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>
                    <div class="container">
                        <div>
                                <router-link :to="{ name: 'page.opdDept' }">
                                        <font-awesome-icon icon="fa-solid fa-arrow-left" style=" margin-right: 10px; font-size: 14px;" />
                                        <span class="m-none" style="font-size: 12px;" >Back</span>
                                </router-link>
                            </div>
                    <div v-if="!getLoaderStatus" class="row ">
                        <div class="col-12 col-lg-12 pos-relative  ">
                            <div class=" card-primary-outline mt-2 d-flex" style="box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; justify-content: left;">

                         
                            <div class="d-flex px-5">                       
                                <div style="width: 60px; height: 60px; border-radius: 4px;">
                                    <img  :src="getFetchedDocDetail?.image" style=" object-fit: contain; height: 60px; width: 100%; "/>
                                </div>
                                <div style="font-size: 16px;">
                                    <span class="px-2">{{
                                        getFetchedDocDetail.doctor_name
                                    }}</span>
                                    <div class=" text-small px-2 ">{{ getFetchedDocDetail.designation }}</div>
                                    <div class=" text-small px-2 ">
                                        <span v-for="docTime in getFetchedDocDetail.doctor_time_table" 
      :key="docTime" 
      class="mx-1 px-1  py-1 text-white" 
      style="background-color: var(--foundation----primary---p100); 
             font-size: x-small;
             justify-content: center; 
             border-radius: 50%;
             display: inline-flex; 
             align-items: center; 
             width: 24px; 
             height: 24px; ">
    {{docTime?.substr(0,3)}}
</span>

                                    </div>
                                </div>
                                
                       
                            </div>
                  

                            </div>
                            <div class=" py-4" >
                                <!-- <span style="font-size: 18px; margin-top: 5rem;">Please select one of the following</span> -->
                                <div class="row">
                                    <div class="col-sm-8">
                                        <VDatePicker v-model.string="date" mode="date" :masks="masks" expanded  :min-date='new Date()' :is-required="true"/>
                                    </div>
                                    <div class="col-sm-4" v-for="(ticket, index) in filteredTickets" :key="index"> 
                                        <div>
                                            <div class="row" v-if="getSelectedScheduleTimeSlotData?.length">
                                                <span class="mb-2">Please Select a Time</span>
                                                <div @click="selectTime(dt.id)"  class=" col-12 col-xl-4 col-lg-4" style="cursor: pointer;" v-for="(dt,index) in getSelectedScheduleTimeSlotData" :key="index">
                                                        
                                                        <p :class="dt.id === selectedTimeSlot ? 'selected_time_slot' : ''" class="formatted_time text-center"> <font-awesome-icon icon="fa-solid fa-clock"/> &nbsp;{{dt.time}}</p>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p class="px-2 py-2 text-center" style="color: var(--foundation----secondary---s400); font-weight: 600; background-color: var(--foundation----secondary---s50); border-radius: 5px; ">No Schedule Found</p>
                                            </div>
                                            <div v-if="true">
                                                <button :disabled="selectedTimeSlot == '' || isDisableBookButton" data-bs-toggle="modal" data-bs-target="#bookticket" @click="proceedtobook(0)" class="btn mt-3 m-auto btn-proceed " type="button">
                                                    <span class="opd_ticket_time">Proceed To Book</span>
                                                    <br>
                                                    <!-- <span class="opd_ticket" >Rs {{getDepartmentOpdChargeData }}</span> -->
                                                    <!-- <span class="opd_ticket_time" >Rs {{ticket.time}}</span> -->
                                                </button>
                                            </div>
                                            <label for="" v-if="getErrors ? (getErrors['message'] ? true : false) : false"
                                            class="text-danger"> {{ getErrors ? (getErrors["message"] ? getErrors['message'] : "") : "" }}</label>
                                        </div>
                                        
                                    </div>
                                </div>
                               

                            </div>
                        </div>
                        <!-- <div class="col-12 col-lg-4 ">
                            <div class=" mt-2 d-flex doctors_height" style="flex-direction: column;">
                                <span class="fo-text-color-primary-p500" style="font-size: 26px; 
                                background: white;"><span style="font-weight: 600;">{{ getDoctorsData.length }}</span> Doctors in <span style="font-weight: 600;">{{dept[0].department }}</span> </span>

                                <noRecordComp v-if="filteredDoctor.length < 1 && !getLoaderStatus" :title="'NO RECORD FOUND'">
                                </noRecordComp>
                                <div style="    padding: 5px 15px 25px 7px; height: 320px; overflow-y: scroll;">
                                    <div class="row">
                                    <div v-for="(doc, index) in filteredDoctor" :key="index" class=" col-12 col-md-6 col-lg-12 col-xl-6 " style="padding-right: 0;">
                                        <div class="row packages p-0 mx-1 doctor_packages align-items-center">
                                            <div class="col-3  ">
                                                <font-awesome-icon icon="fa-solid fa-user" style="font-size: 24px; color: var(--foundation----primary---p400); background-color: var(--foundation----primary---p50); border-radius: 50px;" class="m-auto px-2 py-2 "/>
                                            </div>
                                            <div class="col-9 ">
                                                <span style="line-height: 1; font-weight: 700;">{{ doc.doctor_name }}</span>
                                                <br>
                                                <span style="line-height: 1;">{{doc.code}}</span>
                                                <br>
                                                    <span class="fo-text-color-secondary-s500 fo-bg-color-secondary-s50 px-1 py-1" style="border-radius: 5px; line-height: 2;">
                                                    Designation
                                                    </span>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                
                            </div>
                            
                        </div> -->
                        
                    </div>
                </div>
                
                </div>
        <bookticket></bookticket>
            </div>
    <!-- </div> -->
</template>

<script setup>
//     import { Calendar, DatePicker } from 'v-calendar';
// import 'v-calendar/style.css';

import { computed, ref, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import SpinnerDataLoader from "@/components/loaders/spinnerloader";
import noRecordComp from "@/components/norecord";
import { useAuthLogin } from "@/core/modules/auth/login";
import { usePayment } from "@/core/modules/payment"
import { useRoute } from 'vue-router'
import bookticket from "@/components/modal/bookticket";
import {useCommonStore} from '@/core/modules/common'
import {useStoreAppointment} from "@/core/modules/appointment/appointment"
const storeAppointment=useStoreAppointment()
const {getStoreLink} = storeToRefs(useCommonStore())

// const { getErrors } = storeToRefs(useAuthLogin());
const isDisableBookButton=ref(false)

const {getLoaderStatus,getfetechedSpecialitiesList,getSelectedScheduleDate,getSelectedScheduleTimeSlotData,getFetchedDocDetail}=storeToRefs(useStoreAppointment())
const { getPaymentErrors } = storeToRefs(usePayment())
const route = useRoute()
const selectedDepartment = ref(null)
const date = ref(null);
const selectedTimeSlot = ref('')
const masks = ref({
  modelValue: 'YYYY-MM-DD',
});

const opd_tickets = ref([
    {   
        id: 1,
        category: 'OPD Specialized',
        rate: '501',
        time: '7:00 AM To 7:00 PM',
        department_id: 65
    },
    {   
        id: 2,
        category: 'Regular',
        rate: '26',
        time: '7:00 AM To 7:00 PM',
        department_id: 0
    },

])
onMounted(async() => {
    
    let isAppCalc = getStoreLink.value.some(element => {
    if (element.name?.toLowerCase() === 'appointment') {
        return element.submenu?.some(element1 => {
            if (element1.is_batch === 0) {
                // Check if the country code matches
                return route.params.country_id != element1.country_id;
            }
        });
    }
});
    await storeAppointment.fetchScheduleDates({is_immigration:isAppCalc,doc_id:route.params.id})

// await store.getDepartmentOpdCharge(route.params.id);
date.value = getSelectedScheduleDate.value
// await store.getScheduleTimeSlots(date.value, route.params.id);
selectedDepartment.value = route.params.id;
let opd_id = window.localStorage.getItem('opd_id');
if(opd_id) {
    window.localStorage.removeItem('opd_id')
}
let schedule_id = window.localStorage.getItem('schedule_id');
if(schedule_id) {
    window.localStorage.removeItem('schedule_id')
}
let rate = window.localStorage.getItem('rate');
if(rate) {
    isDisableBookButton.value=false;
    window.localStorage.removeItem('rate')
}
let bookpay = window.localStorage.getItem('bookpay');
if(bookpay) {
    window.localStorage.removeItem('bookpay')
}
})


// const dept = computed(() => {
//     return getDepartmentListData.value.filter(department => {
//         return route.params.id == department.id;
//     })
// })

// const filter = ref('')

// const filteredDoctor = computed(() => {
//     let filteredData = getDoctorsData.value;
//     if (filteredData)
//         return getDoctorsData.value.filter(appointment => {
//             return appointment.doctor_name.toLowerCase().includes(filter.value.toLowerCase())
//         })
//     else
//         return getDoctorsData
// })
const filteredTickets = computed(() => {
    if(route.params.id == 65){
        return opd_tickets.value.filter(opds => {
        return opds.department_id != 0;
    })
    }
    else{
    return opd_tickets.value.filter(opds => {
        return opds.department_id == 0;
    })}
})
const disabledDates = computed(() => {
    return new Date(Date.now() - 86400000);
})

watch(async () => date.value, async(newDate, oldDate) => {
    console.log(date.value)
    
    selectedTimeSlot.value = ""
    window.localStorage.removeItem('schedule_id')
    await storeAppointment.getScheduleTimeSlots(date.value, route.params.id )
    console.log(oldDate)
    let oldDateIs=await oldDate
    let newDateIs=date.value?new Date(date.value):new Date()
    let isAppCalc = getStoreLink.value.some(element => {
    if (element.name?.toLowerCase() === 'appointment') {
        return element.submenu?.some(element1 => {
            if (element1.is_batch === 0) {
                // Check if the country code matches
                return route.params.country_id != element1.country_id;
            }
        });
    }
});

// isAppCalc will be true if there are elements with is_batch === 0 and country_id !== route.params.country_id, otherwise, it will be false.



    const parmas={is_immigration:isAppCalc,doc_id:route.params.id,month:newDateIs.getMonth()+1,year:newDateIs.getFullYear(),country_id:route.params.country_id}
    storeAppointment.fetchScheduleDates(parmas)
//     if (new Date(oldDateIs).getMonth()!=new Date(date.value).getMonth() || new Date(oldDateIs).getFullYear()!=new Date(date.value).getFullYear()){
//     storeAppointment.fetchScheduleDates({is_immigration:false,doc_id:route.params.id,month:newDateIs.getMonth()+1,year:newDateIs.getFullYear(),country_id:1})
//     console.log("reload")
//     }
//     else{
//  storeAppointment.fetchScheduleDates({is_immigration:false,doc_id:route.params.id})
//     }

    
   
   
})
function selectTime(id){
    selectedTimeSlot.value = id;
    window.localStorage.setItem('schedule_id', selectedTimeSlot.value)
}
function proceedtobook(rate) {
    isDisableBookButton.value=true;
    window.localStorage.setItem('rate',rate);
}
</script>

<style scoped>
    .hide {
        display: none;
    }
    .selected_card {
        border: 1px solid var(--foundation----primary---p400);
    }
    .selected_card:hover {
        border: 1px solid var(--foundation----primary---p400);
        background-color: var(--foundation----primary---p50);
    }
   
    .selected_time_slot {
        box-shadow:1px 3px 1px 1px var(--foundation----success---s400);
        background-color: var(--foundation----success---s50);
        color: var(--foundation----success---s400);
    }
    .btn-proceed {
        border: 1px solid var(--foundation----success---s400);
        color: var(--foundation----success---s400);
        background-color: var(--foundation----success---s50);
    }
    .btn-proceed:hover {
        border: 1px solid var(--foundation----success---s400);
        color: var(--foundation----success---s400);
        background-color: var(--foundation----success---s50);
        box-shadow:1px 3px 1px 1px var(--foundation----success---s400);
    }
</style>