<template>
    <modalComp :idmodal="'bookticket'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Book Ticket</h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <!-- <div class="strongpassword mt-4">Please choose a payment option
            </div> -->
            <div class="strongpassword">Do you want to book or pay for the appointment?
            </div>
            <!-- <div class="mt-4">
                <img style="max-width: 100px; cursor: pointer; " :class="selectedPayment == true ? 'active-pay' : ''"  @click="selectPayment" src="@/assets/images/khalti.svg" alt="">
            </div> -->
        </template>
        <template v-slot:footer>
            <div class="col-6 m-0 p-0" v-for="(btns,index) in bookpay" :key = index>
                <button type="button" :class="btns.button_id == 1 ? 'btn-primary' : ''" class="btn border-top btn-lg w-100" @click="proceedPayment(btns.button_id)" data-bs-dismiss="modal"
          >{{btns.button_type}}</button>
            </div>
            <!-- <div class="col-6 m-0 p-0">
                <button type="button" data-bs-dismiss="modal" class="btn btn-primary btn-lg w-100" >
                    Pay</button>
            </div> -->
        </template>
    </modalComp>
    <choosepayment></choosepayment>
</template>

<script setup>
    import { Modal } from 'bootstrap'
import modalComp from '@/components/common/modal'
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSelfAppointment } from "@/core/modules/self/appointment"
import { useAuthLogin } from '@/core/modules/auth/login'
import { usePayment } from "@/core/modules/payment"
import {useManageUser} from '@/core/modules/auth/user'
import router from "@/router/routes";
import choosepayment from "@/components/modal/choosepayment";
import { useRoute } from 'vue-router'
const { getUser } = storeToRefs(useManageUser());
const managePaymentStore = usePayment()
const { getIsAuthorized}  =  storeToRefs(useManageUser())
const store = useSelfAppointment()
const authStore = useAuthLogin()
const route = useRoute()
const selectedPayment = ref(null)
const props = defineProps({
})
const bookpay = ref([
    {
        button_type : 'Book',
        button_id : 0
    },
    {
        button_type : 'Pay',
        button_id : 1
    },
])

function proceedPayment(id){
    window.localStorage.setItem('opd_id', route.params.id);
    window.localStorage.setItem('bookpay', id);
    if(getIsAuthorized.value){
        var body = {
            schedule_list_id: window.localStorage.getItem('schedule_id')
        }
        if(id == 0){
            // managePaymentStore.checkSlotAvaliabilty({schedule_list_id:body.schedule_list_id,country_id:route.params.country_id})
            // managePaymentStore.savePatient(body);
            router.push({name: "page.immgBook",params:{
        id:route.params.id,
        country_id:route.params.country_id,
        // country_id:this.$route.params.country_id,
       }})
            route
        }
        else {
            let  modal1 = Modal.getOrCreateInstance('#choosepayment');
            modal1.show();
        
        }
    }
        else {
            
            router.push({name: 'page.auth.login'})
        }
}
</script>
