<template>
    <!-- <div class="row"> -->
        <div class="col-sm-12">
            <div class="rounded shadow-sm">
                <div class="osahan-privacy bg-white rounded shadow-sm p-4">
                    <!-- <div class="row">
                        <div class="col-sm-2" style="cursor: pointer;" v-if="getIsAuthorized">
                            <router-link :to="{ name: 'page.dashboard' }">
                                <font-awesome-icon icon="fa-solid fa-arrow-left" style=" margin-right: 10px;" />
                                <span>Back</span>
                            </router-link>
                        </div>
                    </div> -->

                    <div class="row appointment-header ">
                        <!-- <div class="appointment-tabs col-sm-10">
                            <tabList @click="clickTab(tabs, index)" :class="{ activeTab: index == selectedTab }"
                                class="dateTabList" v-for="(tabs, index) in appointment_tabs" :key="index" :date="tabs">
                            </tabList>
                        </div> -->
                        <div  :class="getIsAuthorized ? 'col-sm-5' : ''" style="cursor: pointer;" v-if="getIsAuthorized">
                            <router-link :to="{ name: 'page.dashboard'  }" >
                                <font-awesome-icon icon="fa-solid fa-arrow-left" style=" margin-right: 10px;" />
                                <span>Back</span>
                            </router-link>
                                    
                            
                        </div>
                        <div :class="getIsAuthorized ? 'col-sm-10' : 'col-sm-8 col-md-6 col-xl-8'">
                        <div class="all-department">
                            <span style="font-size: var(--heading-3---medium)" class="fw-bold"></span>
                        </div>
                    </div>
                        <div class="col-sm-12" :class="getIsAuthorized ? 'col-sm-12' : 'col-md-6 col-xl-4'" style="position: relative">
                            <input type="text" v-model="filter" class="form form-control text-lg"
                            style="font-size: 1.3rem;"
                            placeholder="Search Specialities, Doctors" />
                            <font-awesome-icon class="searchIcon" icon="fa-solid fa-search" />
                        </div>
                    </div>
                    <div :class="getIsAuthorized ? 'col-sm-12' : 'col-sm-12 col-md-12 col-xl-12'">
                        <div class="all-department">
                            <span style="font-size: var(--heading-3---medium)" class="fw-bold">All Specialities</span>
                        </div>
                    </div>


                    <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>
                    <noRecordComp v-if="filteredSpecialites.length < 1 && !getLoaderStatus"
                        :title="'NO RECORD FOUND'">
                    </noRecordComp>

                    <div v-if="!getLoaderStatus" class="row department-list">
                        <div class="col-12 col-md-4 col-xl-3 py-2 px-2 m-0" v-for="(spec, index) in filteredSpecialites" :key="index">
                            <div class="card package-card" @click="fetchDoctors(spec.id)">
        <div class="card-body">
            <div class="package-image-container">
                <img :src="spec.image" class="card-img-top" :alt="spec.name" />
            </div>
            <h5 class="card-title fo-text-color-primary-p500 fw-bold ">{{ spec.name }}</h5>
        </div>
    </div>
                        </div>
                    </div>


                    <div :class="getIsAuthorized ? 'col-sm-12' : 'col-sm-12 col-md-12 col-xl-12'">
                        <div class="all-department" v-if="filter">
                            <span style="font-size: var(--heading-3---medium)" class="fw-bold">All Doctors</span>
                        </div>
                    </div>


                    <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>
                    <noRecordComp v-if="filteredDoctors.length < 1 && !getLoaderStatus"
                        :title="'NO RECORD FOUND'">
                    </noRecordComp>

                    <div v-if="!getLoaderStatus && filter" class="row department-list">
                        <div class="col-12 col-md-4 col-xl-3 py-2 px-2 m-0" v-for="(doc, index) in filteredDoctors" :key="index">
                            <div class="card package-card" @click="fetchDoctors(doc.id)">
        <div class="card-body">
            <div class="package-image-container">
                <img :src="doc.image" class="card-img-top" :alt="doc.doctor_name" />
            </div>
            <h5 class="card-title fo-text-color-primary-p500 fw-bold p-1">{{ doc.doctor_name }}</h5>
        </div>
    </div>
                        </div>
                    </div>
                
                </div>


            </div>
        </div>
        <multiplecharges :deptId = "department"></multiplecharges>

</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, computed } from "vue";
import { storeToRefs } from "pinia";
import tabList from "@/components/tabList/tabList";
import SpinnerDataLoader from "@/components/loaders/spinnerloader";
import noRecordComp from "@/components/norecord";
import { useSelfAppointment } from '@/core/modules/self/appointment';
import {useStoreAppointment} from "@/core/modules/appointment/appointment";
import {useManageUser} from '@/core/modules/auth/user'
import multiplecharges from '@/components/modal/multiplecharges'
import { Modal } from 'bootstrap'
const manageUserStore  = useManageUser()
const { getIsAuthorized}  =  storeToRefs(useManageUser())
const store = useSelfAppointment();
const storeAppointment=useStoreAppointment()
const month_index = ref('');
const year = ref('');
const dept_id = ref(null)
// const is_reschedule = ref(false)
const { getAppointmentData, getImmigrationDoctorDetail, getDepartmentListData, getMultiPriceCatDeptId } = storeToRefs(
    useSelfAppointment()
);
const {getLoaderStatus,getfetechedSpecialitiesList}=storeToRefs(useStoreAppointment())
// const selectedTab = ref("0");
const filter = ref("");
// const appointment_tabs = ref([
//     "Hospital Appointment"
// ]);
const router = useRouter();

onMounted(async () => {
    // store.fetchImmigrationDoctorDetails();
    await storeAppointment.fetchSpecialities()
    await store.getDepartmentList();
})

function fetchDoctors(id) {
    if(id == getMultiPriceCatDeptId.value[0]){
        dept_id.value = id;
        let  modal1 = Modal.getOrCreateInstance('#multiplecharges');
        modal1.show();
    }
    else {
     store.getDepartmentOpdCharge(id);
     store.getDoctors(id);
    }
}
const department = computed(() => {
    return dept_id.value
})
// function clickTab(tabs, index) {
//     if (index === 1) {
//         store.getScheduleDates(getImmigrationDoctorDetail.value.id, getImmigrationDoctorDetail.value.speciality_id, year.value, month_index.value, getImmigrationDoctorDetail.value, is_reschedule.value)
//     }
//     else if (index === 2) {
//         store.getScheduleDates(getImmigrationDoctorDetail.value.id, getImmigrationDoctorDetail.value.speciality_id, year.value, month_index.value, getImmigrationDoctorDetail.value, is_reschedule.value)
//     }
// }

// function fetchScheduleDates(id, dept, doc) {
//     store.getScheduleDates(id, dept, year.value, month_index.value, doc)
// }

const filteredSpecialites = computed(() => {
    let filteredData = getfetechedSpecialitiesList.value.specialities;
    if (filteredData)
        return getfetechedSpecialitiesList.value.specialities.filter((spec) => {
            return spec.name
                ?.toLowerCase()
                ?.includes(filter.value.toLowerCase());
        });
    else return getDepartmentListData;
});
const filteredDoctors = computed(() => {
    let filteredData = getfetechedSpecialitiesList.value.doctors;
    if (filteredData)
        return getfetechedSpecialitiesList.value.doctors.filter((doc) => {
            return doc.doctor_name
                ?.toLowerCase()
                ?.includes(filter.value.toLowerCase());
        });
    else return getDepartmentListData;
});
// const filteredDoctors = computed(() => {
//     let filteredData = getAppointmentData.value.doctors;
//     if (filteredData)
//         return getAppointmentData.value.doctors.filter((appointment) => {
//             return appointment.doctor_name
//                 .toLowerCase()
//                 .includes(filter.value.toLowerCase());
//         });
//     else return filteredData;
// });
</script>

<style scoped>
.appointment-tabs {
    display: flex;
}
.package-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;    
    height: 225px; /* Fixed height */
    overflow: hidden; /* Hide overflow content */
}

.package-card:hover {
    transform: translateY(-6px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.card-body {
    padding: 0.25rem;
}

.package-image-container {
    overflow: hidden;
    border-radius: 10px;
    height: 165px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.package-image-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.card-title {
    text-align: center;
    padding: 4px;
}

</style>
