<template>
    <!-- <div class="row"> -->
         <div class="container">
            <!-- <searchheader @search="search"/> -->
</div> 
<div class="container  ">
<div class=" " >
    
      

            <!-- Wellness Table for Today Data -->
            <div style="margin-bottom: 40px; overflow-x: scroll;" v-if="!getLoaderStatus " class="px-3 container">
 
                        <div class="m-4 bg-white p-5 " v-for="(trxn, index) in getNewsAndNotification" :key="index">
                            <h5>{{ trxn.title}}</h5>  <span>{{ new Date(trxn.created_at).toLocaleDateString() }}</span>
                            <div v-html="trxn.description">
                        
                         
                        </div>
             
             
            </div>
            <!--End of Wellness Table for Today Data -->
        </div>
        </div>
</div>


</template>

<script setup>
import '@/assets/css/pagination.css'
import router from '@/router/routes'
import { onMounted, ref, watch, onBeforeMount, onBeforeUnmount,computed } from 'vue';
import {useManageUser} from '@/core/modules/auth/user'
import { storeToRefs } from 'pinia'
import {useStoreAgent} from "@/core/modules/agent/agent";
import searchheader from '../../components/searchheader/searchheader.vue';
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import noRecordComp from '@/components/norecord'
import Pagination from 'v-pagination-3';



const {getNewsAndNotification,getLoaderStatus}=storeToRefs(useStoreAgent());



const {getUser} =  storeToRefs(useManageUser())


const agentStore=useStoreAgent()

const user = ref('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6FI5wnFojJPN6gWk1lF7mIAdzd3D8dhQaHVRd1snFHw&s')
const date = new Date();
let d = date.getHours();

onMounted(async() => {
    await agentStore.fetchAgentNewsList();
   
})









</script>

