<template>
  <!-- <div class="row"> -->
    <div class="col-sm-12">
      <div class="rounded shadow-sm">
        <div class="osahan-privacy bg-white rounded shadow-sm p-4">
          <div class="row px-5 py-5 p-0">
            <div class="col-sm-7">
              <div class="contact-intro">
                <h3 class="fw-700 py-2">Get In Touch With Us</h3>
                <span >
                  We'd love to hear from you. Whether you have a question about features, pricing, need a demo
                  or anything else, our team is ready to answer all your questions.
                </span>
                <div class="contact-icons-container">
                  <div class="contact-icon" style="margin-top: 20px">
                    <div class="contact-icon-left">
                      <font-awesome-icon
                        icon="fa-solid fa-home"
                        class="h6 m-0 fa-icon"
                      />
                    </div>
                    <div class="contact-icon-right ml-15">
                      <span class="fw-700 fs-18">Our Location</span>
                      <span>Kumari Marga, Tripura Marg, 44600 2nd Floor, 44600</span>
                    </div>
                  </div>
                  <div class="contact-icon">
                    <div class="contact-icon-left">
                      <font-awesome-icon
                        icon="fa-solid fa-phone"
                        class="h6 m-0 fa-icon"
                      />
                    </div>
                    <div class="contact-icon-right ml-15">
                      <span class="fw-700 fs-18">Phone Number</span>
                      <span>+9779840029313</span>
                    </div>
                  </div>
                  <div class="contact-icon">
                    <div class="contact-icon-left">
                      <font-awesome-icon
                        icon="fa-solid fa-envelope"
                        class="h6 m-0 fa-icon"
                      />
                    </div>
                    <div class="contact-icon-right ml-15">
                      <span class="fw-700 fs-18">Our Website</span>
                      <a target="blank" href="https://merakihealthsolution.com/">merakihealthsolution.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-5">
                <!-- <maps></maps> -->
                <div class="form-container">
                    <!-- <form action="">
                        <div class="form-group mb-3" style="position: relative">
                            <input style="height: 50px" class="form-control mb-10"  placeholder="Your Name">
                        </div>
                        <div class="form-group mb-3" style="position: relative">
                            <input style="height: 50px" class="form-control mb-10" placeholder="Your Email">
                        </div>
                        <div class="form-group mb-3" style="position: relative">
                            <input style="height: 50px" class="form-control mb-10" placeholder="Your Phone Number">
                        </div>  
                        <div class="form-group mb-3" style="position: relative">
                            <textarea  class="form-control mb-10" rows="4" cols="50" placeholder="Your Message"></textarea >
                        </div> 
                        <button style="width: 100%;" class="btn btn-lg btn-primary" type="button">
                            <span class="ml-2 text-lg">Submit</span>
                        </button>                       
                    </form> -->
                    <img style="width: 100%; cursor: pointer;" src="../assets/images/map.jpg" alt="map" @click="openGoogleMaps()">
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>
<script setup>
// import maps from '@/components/map'
function openGoogleMaps(){
    const urlSuffix = '27.693132, 85.317036'
    window.open("https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
          "_blank")
}
</script>
<style scoped>
/* .form-container {
    padding: 40px 20px;
    background-color: var(--foundation----primary---p75);
    border-radius: 5px;
} */
.contact-icon {
    display: flex;
    margin-bottom: 30px;
}
.contact-icon-left {
    line-height: 5;
    background-color: var(--foundation----primary---p200);
    border-radius: 10px;
    padding: 0 10px;
    width: 48px;
    position: relative;
}
.contact-icon-right {
    display: flex;
    flex-direction: column;
}
.fa-icon {
    position: absolute;
    left: 16px;
    top: 16px;
    color: var(--foundation----neutral---n10)
}
</style>
