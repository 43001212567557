<template>
    <!-- <div class="row"> -->
         <div class=" container">
            <searchheader @search="search"/>
</div> 
<div class="container  ">
<div class="col-sm-12 bg-white" >
    <!-- {{ tableData }} -->
    
            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>

            <!-- Wellness Table for Today Data -->
            <div style="margin-bottom: 60px; overflow-x: scroll;" v-if="!getLoaderStatus " class="px-3">
                <div ref="table"></div>


                <!-- <table class="self-table wellness-table" >
                    <thead>
                        <tr>
                            <th>Transaction Date</th>
                            <th>Description</th>
                            <th>Debit Amt(NPR.)</th>
                            <th>Credit Amt(NPR.)</th>
                            <th>Balance(NPR.)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tableRow" v-for="(trxn, index) in getAgentTransactionList.data" :key="index">
                            <td>{{ trxn.trans_date }}</td>
                            <td>{{ trxn.bill }}</td>
                            <td>{{ trxn.debt_amt }}</td>
                            <td>{{ trxn.credit_amt }}</td>
                            <td>{{ trxn.balance }}</td>
                         
                        </tr>
                    </tbody>
                </table> -->
                <!-- <pagination v-model="getAgentTransactionList.current_page" :records="getAgentTransactionList.total" :per-page="getAgentTransactionList.per_page"  @paginate="searchPagination"  /> -->
            </div>
            <!--End of Wellness Table for Today Data -->
        </div>
        </div>

</template>

<script setup>
import '@/assets/css/pagination.css'
import { onMounted, ref, watch, onBeforeMount, onBeforeUnmount,computed,reactive } from 'vue';
import { storeToRefs } from 'pinia'
import {useStoreAgent} from "@/core/modules/agent/agent";
import searchheader from '../../components/searchheader/searchheader.vue';
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator_bootstrap5.min.css';


const {getAgentDueAmount,getAgentData,getAgentTransactionList,getLoaderStatus}=storeToRefs(useStoreAgent());




const table = ref(null); //reference to your table element
  const tabulator = ref(null); //variable to hold your table
  const tableData = reactive([
]);
// console.log(getAgentTransactionList)
watch(() => getAgentTransactionList.value, (n) => tableData.value=getAgentTransactionList.value);


  
const agentStore=useStoreAgent()

const user = ref('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6FI5wnFojJPN6gWk1lF7mIAdzd3D8dhQaHVRd1snFHw&s')
const date = new Date();
let d = date.getHours();

onMounted(async() => {
    await agentStore.fetchAgentData();
    await agentStore.fetchAgentsTotalDueData();
    let params=JSON.parse(localStorage.getItem('searchParams'))
    await agentStore.FetchAgentTransactionList({...params})
    tabulator.value = new Tabulator(table.value, {
        // responsiveLayout:true,
        // layout:"fitData",
        reactiveData: true,
                  placeholder: "No record(s) found",
                  pagination: "local",
        data: tableData.value, //link data to table
        paginationSize: 25,
                  paginationSizeSelector: [50, 100, 200],
                  paginationCounter: 'rows',
                  persistentSort: false,
                  layout:"fitColumns",
        columns: [
        {title:"Transaction Date", field:"trans_date",width:200,headerFilter: true},
        {title:"Description", field:"bill",width:450,headerFilter: true},
        {title:"Debit Amt(NPR.)", field:"debt_amt", hozAlign:"right",width:200,headerFilter: true},
        {title:"Credit Amt(NPR.)", field:"credit_amt", hozAlign:"right",width:200,headerFilter: true},
        {title:"Balance(NPR.)", field:"balance", hozAlign:"right",width:200,headerFilter: true}   
        ], //define table columns
      });
    //   return{tabulator, table};
})
// function searchPagination(data){
//     console.log(data)
//     let params=JSON.parse(localStorage.getItem('searchParams'))
//     console.log(params)
//     agentStore.FetchAgentTransactionList({...params,page:data})



// }

async function search(params){
    // console.log(params)
    localStorage.setItem('searchParams',JSON.stringify(params))
   await agentStore.FetchAgentTransactionList(params)
   tabulator.value = new Tabulator(table.value, {
        // responsiveLayout:true,
        // layout:"fitData",
        reactiveData: true,
                  placeholder: "No record(s) found",
                  pagination: "local",
        data: tableData.value, //link data to table
        paginationSize: 25,
                  paginationSizeSelector: [50, 100, 200],
                  paginationCounter: 'rows',
                  persistentSort: false,
                  layout:"fitColumns",
        columns: [
        {title:"Transaction Date", field:"trans_date",width:200,headerFilter: true},
        {title:"Description", field:"bill",width:450,headerFilter: true},
        {title:"Debit Amt(NPR.)", field:"debt_amt", hozAlign:"right",width:200,headerFilter: true},
        {title:"Credit Amt(NPR.)", field:"credit_amt", hozAlign:"right",width:200,headerFilter: true},
        {title:"Balance(NPR.)", field:"balance", hozAlign:"right",width:200,headerFilter: true}   
        ], //define table columns
      });
}







</script>
<style >
.tabulator .tabulator-header .tabulator-col {
    background-color: rgba(30, 143, 255, 0.438);
    /* border-right: none; */
}
</style>
