import axios from "axios";
import { defineStore } from "pinia";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {useManageUser} from '@/core/modules/auth/user'
import { storeToRefs } from 'pinia'
import { api } from "../services/api";


export const usePayment = defineStore(
  "payment",
  {
    state: () => ({
      errors: null,
      loaderStatus: false,
      successresponse: null,
      booking_id: null,
      ePaymentConfig:{},
    }),
    getters: {
      getPaymentErrors: (state) => {
        return state.errors;
      },
      getLoaderStatus: (state) => {
        return state.loaderStatus;
      },
      getSuccessResponse: (state) => {
        return state.successresponse
      },
      getBookingId : (state) => {
        return state.booking_id
      },
      getEpaymentConfig:(state)=>{
        return state.ePaymentConfig
      }
    },
    actions: {
      async savePayment(scheduleID, deptID,rate) {
        try {
          this.loaderStatus = true;
          await axios
          .get("/new/khaltiPayment", {
            params: {
              schedule_id: scheduleID ,
              dept_id: deptID,
              amount: rate
            }
          })
          .then((response) => {
            this.loaderStatus = false;
            if(response.status == 200){
            window.location.href=response.data.response.payment_url;
            }
          })
          .catch((error) => {
            this.loaderStatus = false;
            this.errors = error.data;
            Swal.fire({
              title: error.response.data.msg,
              text: error.response.data.error,
              icon: 'info',
              confirmButtonText: 'OK'
            })
            .then(() => {
              window.location.reload()
          });
            
          });
        }
        catch (error) {
          this.errors = error.data;
          Swal.fire({
            title: error.response.data.msg,
            text: error.response.data.error,
            icon: 'error',
            confirmButtonText: 'OK'
          })
          .then(() => {
            window.location.reload()
        });
        }
      },
      async getAllEpaymenyConfig() {
        this.errors = null;
        this.ePaymentConfig={}
        try {
          await axios
            .get(api.payment.fetchEpaymentVendorsConfig)
            .then((response) => {
            this.ePaymentConfig=response.data.data
            })
            .catch((error) => {
        this.ePaymentConfig={}

                });
        } catch (error) {
          this.errors = error;
          console.log(error)
        }
      },
      async checkSlotAvaliabilty(params) {
        this.errors = null;
        const { getIsAuthorized}  =  storeToRefs(useManageUser())

        try {
          this.loaderStatus =  true;
          await axios
            .get("/validateAndGetBookingId/",{params:params})
            .then((response) => {
              this.loaderStatus =  false;
              if(response.status == 200){
                this.booking_id = response.data.booking_id
                if(getIsAuthorized.value){
                this.savePatient(response.data.booking_id, response.data.schedule_list_id)}
                else {
                  this.$router.push({ name: 'page.auth.signup'})
                }
              }
            })
            .catch((error) => {
              console.log(error)
              Swal.fire({
                title: error.response.data.msg,
                text: error.response.data.error,
                icon: 'info',
                confirmButtonText: 'OK'
              })
              .then(() => {
                if(window.localStorage.getItem('bookpay')){
                  window.localStorage.removeItem('bookpay')
                }
                window.location.reload();

              })
            });
        } catch (error) {
          this.errors = error;
          console.log(error)
          this.loaderStatus =  false;
        }
      },
      async savePatient(bookid, scheduleid) {
        this.errors = null;
        let booking_obj = {

        }
      const { getIsAuthorized}  =  storeToRefs(useManageUser())
        if(getIsAuthorized.value){
        booking_obj = {
          booking_id : bookid,
          country_code:1,
          id : parseInt(scheduleid)
        }
      }
      else {
        booking_obj = bookid
      }
        try {
          this.loaderStatus =  true;
          await axios
            .post("/bookAppointment", booking_obj)
            .then((response) => {
              this.loaderStatus =  false;
              if(response.status === 200) {
                this.successresponse = response.data;
                Swal.fire({
                  title: response.data.title,
                  text: response.data.message,
                  icon: 'success',
                  confirmButtonText: 'OK'
                })
                .then(() => {
                  this.$router.push({ name: "page.successappointment" });
              });
              
              }
             
            })
            .catch((error) => {

              console.log(error)
              Swal.fire({
                title: error.response.data.title,
                text: error.response.data.message,
                icon: 'info',
                confirmButtonText: 'OK'
              })
              .then(() => {
                
                if(window.localStorage.getItem('bookpay')){
                  window.localStorage.removeItem('bookpay')
                }
        if(getIsAuthorized.value){
          window.location.reload()
        }
        else {

          this.$router.push({ path: "/appointment/doctor/" + window.localStorage.getItem('opd_id') });
        }

              })
            });
        } catch (error) {
          this.errors = error.response.data;
          this.loaderStatus =  false;
        }
      },

      async esewaPaymentInitialize(params){
        console.log(params)
        let secret_key=this.ePaymentConfig?.esewa?.secretKey;
        let transaction_uuid=params.transaction_uuid
        let total_amount=params.total_amount
        let message=`total_amount=${total_amount},transaction_uuid=${transaction_uuid},product_code=EPAYTEST`
        var hash = CryptoJS.HmacSHA256(message, secret_key);
        console.log(hash)
        var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
        console.log(hashInBase64)

        const form = document.createElement('form');
        form.method = 'POST';
        // path ps: no config so all static 
        form.action = this.ePaymentConfig?.esewa?.esewa_url;

        let newparams={
          "amount": total_amount,
          "failure_url": window.location.origin+"/confirmpayment",
          "product_delivery_charge": "0",
          "product_service_charge": "0",
          "product_code": this.ePaymentConfig?.esewa?.esewa_code,
          "signature": hashInBase64,
          "signed_field_names": "total_amount,transaction_uuid,product_code",
          "success_url": window.location.origin+"/confirmpayment",
          "tax_amount": "0",
          "secret_key":secret_key,
          "total_amount": total_amount,
          "transaction_uuid": transaction_uuid

        }

  for (const key in newparams) {
   
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = newparams[key];
      

      form.appendChild(hiddenField);
    
  }

console.log(form)
  document.body.appendChild(form);
  form.submit();
      },
  
    async khaltiPaymentInitialize(params){
      try {
        this.loaderStatus = true;
        await axios
        .get("/new/khaltiPayment", {params:params})
        .then((response) => {
          if(response.status == 200) {
            this.loaderStatus = false;
            localStorage.setItem('pidx',response.data.pidx)
            window.location.href = response.data.url;

            
        }
        })
        .catch((error) => {
          this.loaderStatus = false;
          this.errors = error.response.data;
        });
      }
      catch (error) {

        this.loaderStatus = false;
        this.errors = error.response.data;
      }
    },
    async paymentResponse(body ){
      try {
        this.loaderStatus = true;
        await axios
        .post("/new/payment/verify", body)
        .then((response) => {
          if(response.status == 200) {
            this.loaderStatus = false;
            this.successresponse = response.data
            Swal.fire({
              title: 'Succesfully Paid',
              // text: response.data.message + ' ' +'for' + ' ' + response.data.appointment_date + ' ' + response.data.appointment_time ,
              text:response.data.data.message,
              icon: 'success',
              confirmButtonText: 'OK'
            })
            .then(() => {
              this.$router.push({ name: "page.self.wellness" });
          })
        }
        })
        .catch((error) => {
          console.log("here")
          
          this.loaderStatus = false;
          this.errors = error.response.data;
          Swal.fire({
            title: 'Error',
            // text: response.data.message + ' ' +'for' + ' ' + response.data.appointment_date + ' ' + response.data.appointment_time ,
            text:error.response.data.data.message,
            // icon: 'failure',
            confirmButtonText: 'OK'
          })
          .then(() => {
            this.$router.push({ name: "page.self.wellness" });
        })
        });
      }
      catch (error) {

        this.loaderStatus = false;
        this.errors = error.response.data;
      }
    },
  }
  }
);
