import axios from "axios";
import { defineStore } from "pinia";
// import { ref } from "vue";
import { useJwtService } from "@/core/services/jwt";
import { useApiHeader } from "@/core/services/apiheader";

export const useSelectAccount = defineStore("selectaccount", {
  state: () => ({
    accounts: [],
    errors: [],
    dataloader: true,
    selectToken:'',
  }),
  getters: {
    getAccounts: (state) => {
      return state.accounts;
    },
    getErrors: (state) => {
      return state.errors;
    },
    getDataLoader: (state) => {
      return state.dataloader;
    },
    getSelectToken:(state)=>{
        return state.selectToken
      }
  },
  actions: {
    async fetchAccounts() {
      //   const jwtService = useJwtService();
      const apiHeader =  useApiHeader();
      try {
        this.setErrors(null)
        this.dataloader = true;
        apiHeader.setHeader();
        await axios
          .get("/user-multiple-account")
          .then((response) => {
            if (response.data.status == 200) {
              this.accounts = response.data.data;
            } else {
              // alert();
              // this.$router.push({name:'page.auth.login'});
            }
            this.dataloader = false;
          })
          .catch((error) => {
            this.dataloader = false;
            this.setErrors(error.response.data)
          });
      } catch (error) {
        this.dataloader = false;
      
      }
    },
    submitContinue(token){
      const jwtService =  useJwtService();
      jwtService.saveToken(token);
    },
    setSelectToken(token){
        this.selectToken =  token;
    },
    setErrors(error) {
      this.errors = error;
      },
  },
});
