<template>
   
    <div class="col-md-6 offset-md-3 auth-height">
      
        <div class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-hidden">
            <div class="osahan-cart-item-profile bg-white p-3">
                <div class="d-flex flex-column">
                    <strongPasswordInfoModal></strongPasswordInfoModal>
                    <h6 class="mb-3 fw-bold">Choose a new password</h6>
                    <form action="">
                        <p>Create a new password that is at least 8 characters long. A strong password is combination of letters, numbers, and punctuation marks.</p>
                        <div class="form-group mb-3" style="position: relative">
                            <div class="d-flex">
                                <input :type="!showPassword?'password':'text'" class="form-control mb-10 my-0" v-model="password.new_password" placeholder="New Password" style="margin-right: 10px;">
                                <button class="btn btn-warning" type="button" data-bs-toggle="modal" data-bs-target="#strongpasswordinfo">
                                    <font-awesome-icon icon="fa-solid fa-question"></font-awesome-icon>
                                </button>
                            </div>
                            <font-awesome-icon class="fo-text-color-primary-p300" @click="showPasswordFun()" :icon="!showPassword?'fa-solid fa-eye-slash':'fa-solid fa-eye'" style="position: absolute;right: 52px; top: 11px; cursor: pointer;" />
                            <label class="text-danger" for="" v-if="getErrors ? (getErrors['message']['password'] ? true: false) : false" >{{ getErrors['message']['password'][0] }}</label>
                        </div>                    
                    </form>
                    <button class="btn btn-primary btn-lg"  @click="onSubmit()" type="button" :disabled="getLoaderStatus">
                      <span class="ml-2 text-lg">Enter</span>  <span v-if="getLoaderStatus" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import strongPasswordInfoModal from '@/components/modal/strongpasswordinfo'
import { ref } from 'vue'
import { reactive } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthForgetPassword, useAuthForgetPasswordRef } from "@/core/modules/auth/forgotpassword"

const store = useAuthForgetPassword()
const storeref = useAuthForgetPasswordRef()
const { getErrors,getLoaderStatus } = storeToRefs(useAuthForgetPassword())
var { password_credentials } = storeToRefs(storeref)
const showPassword = ref(false)
const showConfirmPassword = ref(false)
// var { password } = storeToRefs(storeref)

var password = reactive({
    phone_number: window.localStorage.getItem('phoneNumber'),
    new_password: "",
    new_password_confirmation: ""
})
function onSubmit() {

    password_credentials = {
        'mobile_number' : password.phone_number,
        'password': password.new_password,
        'password_confirmation' : password.new_password_confirmation
    }
    store.submitResetPassword(password_credentials)
}
function showPasswordFun(){
   showPassword.value = (showPassword.value)?false:true;
}
function showConfirmPasswordFun(){
    showConfirmPassword.value = (showConfirmPassword.value)?false:true;
}

</script>

<style>
    .mb-10 {
        margin-bottom: 10px
    }
</style>