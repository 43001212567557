<template>
    <payappointment :selectedDoc="getSelectedDoctor" :appointment_date="appointment_date" :time="time"
        :grand_total="getPaymentInfoData"></payappointment>
    <div class="row">
        <div class="col-sm-12">
            <div class="rounded shadow-sm">
                <div class="osahan-privacy bg-white rounded shadow-sm p-4">
                    <div class="row px-5 doctorSchedule">
                        <div class="col-sm-12">
                            <div style="cursor: pointer">
                                <RouterLink :to="{ name: 'page.appointment' }">
                                    <font-awesome-icon icon="fa-solid fa-arrow-left" style="margin-right: 10px" />
                                    <span>Back</span>
                                </RouterLink>
                                <!-- Selected Doctor Detail -->
                                <div class="packages fo-bg-color-primary-p50">
                                    <div class="doc_intro">
                                        <div>
                                            <img class="doc_image" :src="getSelectedDoctor.image" alt="" />
                                        </div>
                                        <div class="doc_description">
                                            <span style="font-weight: 700 !important" class="fo-text-color-primary-p500 fw-bold">{{
                                                getSelectedDoctor.doctor_name }}
                                              </span>
                                            <span class="fo-text-color-secondary-s500">{{
                                                getSelectedDoctor.specialization
                                            }}</span>
                                            <span class="fo-text-color-secondary-s500">{{
                                                getSelectedDoctor.designation
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <!--End of Selected Doctor Detail -->
                            </div>
                        </div>
                    </div>
                    <div class="row px-5 doctorSchedule">
                        <div class="col-sm-2">

                            <!-- Select Year Section -->
                            <div class="year-section">
                                <span>Select Year</span>
                                <select v-model="year" @change="selectYear(year)" class="form-select">
                                    <option :value="year" v-for="(year, index) in yearOption" :key="index">
                                        {{ year }}
                                    </option>
                                </select>
                            </div>
                            <!--End of Select Year Section -->
                        </div>
                    </div>
                    <div class="row px-5 py-15 doctorSchedule">

                        <!-- Select Month Section -->
                        <div style="display: flex; padding: 10px;" class="col-sm-12">
                            <tabList :class="{ activeTab: index == selectedTab }" @click="getDateSchedule(tabs.ind)"
                                class="dateTabList" v-for="(tabs, index) in restMonth" :key="index" :date="tabs.m">
                            </tabList>
                        </div>
                        <!--End of Select Month Section -->
                        <div class="col-sm-12">
                            <span style="margin-bottom: 10px">Available Appointment Dates and Time</span>
                            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>

                            <!-- Available time slot for the selected month and year -->
                            <div style="margin: 10px 0px;" v-if="!getLoaderStatus">
                                <div v-for="(app_date, index) in getDoctorSchedule" :key="index">
                                    <div v-if="today_date <= app_date.date">
                                        <span class="fw-bold">{{ getDate(app_date.date) }}</span>
                                        <div class="time_slot_container" v-if="checkRescheduleFlag === false">
                                            <div data-bs-toggle="modal" data-bs-target="#bookappointment" @click="
                                                bookAppointment(
                                                    time.date_time,
                                                    time.id,
                                                    time.appointment_date,
                                                    time.time,
                                                    time.schedule_id
                                                )
                                            " class="col-sm-1 time-slot" v-for="(time, index) in app_date.data" :key="index">
                                                <font-awesome-icon style="position: absolute; left: 20px; top: 13px"
                                                    class="fo-text-color-neutral-n50" icon="fa-solid fa-clock" />
                                                <span class="fw-bold">{{
                                                    getTime(time.date_time)
                                                }}</span>
                                            </div>
                                        </div>
                                        <div class="time_slot_container" v-else>
                                            <div @click="
                                                rescheduleApp(
                                                    time.appointment_date,
                                                    time.time,
                                                    time.id,
                                                    time.schedule_id
                                                )
                                            " class="col-sm-1 time-slot" v-for="(time, index) in app_date.data" :key="index">
                                                <font-awesome-icon style="position: absolute; left: 20px; top: 13px"
                                                    class="fo-text-color-neutral-n50" icon="fa-solid fa-clock" />
                                                <span class="fw-bold">{{
                                                    getTime(time.date_time)
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End of Available time slot for the selected month and year-->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modals to reschedule/book appointment -->
        <bookappointment :appointmentId="appointment_id" :reschedule_appointment="checkRescheduleFlag"
            :speciality_id="getSelectedDoctor.speciality_id" :dept_id="getSelectedDoctor.speciality_id"
            :appointment_date_time="appointment_date_time" :time_slot="time_slot_id"></bookappointment>
        <rescheduleappointment :book_reschedule="bookreschedule" :doc_name="docname" :appointment_time="appointmenttime"
            :appointment_date="appointmentdate" :target_schedule="target_schedule_id"></rescheduleappointment>
        <!--End of Modals to reschedule/book appointment -->
    </div>
</template>
<script setup>
import { onMounted, ref } from "vue";

import payappointment from "@/components/modal/payappointment";
import { storeToRefs } from "pinia";
import SpinnerDataLoader from "@/components/loaders/spinnerloader";
import bookappointment from "@/components/modal/bookappointment";
import noRecordComp from "@/components/norecord";
import tabList from "@/components/tabList/tabList";
import { useSelfAppointment } from "@/core/modules/self/appointment";
import { useCalendar } from "@/core/modules/calendar";
import moment from "moment";
import rescheduleappointment from "@/components/modal/rescheduleapp";
const store = useSelfAppointment();
const {
    getCurrentScheduleId,
    getDoctorSchedule,
    getImmigrationDoctorDetail,
    getSelectedDoctor,
    getLoaderStatus,
    getPaymentInfoData,
    getSpecializationData,
    checkRescheduleFlag
} = storeToRefs(useSelfAppointment());
const { getMonths } = storeToRefs(useCalendar());
const restMonth = ref("");
const current_month = ref("");
const appointment_date_time = ref("");
const time_slot_id = ref(null);
var time = ref(null);
const appointment_date = ref(null);
const appointment_id = ref(null);
let d = new Date();
const m = d.getMonth();
const selectedTab = ref('');
const first_month = ref("1");
const selected_year = ref("");
const year = ref("2023");
const yearOption = ref(["2023", "2024", "2025", "2026", "2027"]);
const is_reschedule = ref(false);
const getYear = d.getFullYear();
const current_year = getYear;
const today_date = ref("");
var docname = ref("");
var appointmentdate = ref("");
var appointmenttime = ref("");
var bookreschedule = ref(true);
var target_schedule_id = ref(null);
onMounted(() => {
    // Get Remaining Months if current year is selected
    today_date.value = moment(d).format("YYYY-MM-DD");
    let x = getMonths.value.filter((y) => {
        return y.ind > m;
    });
    restMonth.value = x;
    if (
        getSelectedDoctor.value.speciality_id &&
        checkRescheduleFlag.value === true
    ) {
        store.getScheduleDates(
            getSelectedDoctor.value.id,
            getSelectedDoctor.value.speciality_id,
            selected_year.value,
            selectedTab.value,
            getSelectedDoctor.value,
            checkRescheduleFlag.value
        );
    } else {
        store.getScheduleDates(
            getSelectedDoctor.value.id,
            getSpecializationData.value.id,
            selected_year.value,
            selectedTab.value,
            getSelectedDoctor.value,
            is_reschedule.value
        );
    }

});
// Get Schedule List according to month selected
function getDateSchedule(month_index) {
    window.localStorage.setItem('getM', JSON.stringify(month_index))
    if (selected_year.value > current_year) {
        selectedTab.value = month_index - 1;
    } else {
        selectedTab.value = month_index - m - 1;
    }
    if (
        getSelectedDoctor.value.speciality_id &&
        checkRescheduleFlag.value === true
    ) {
        store.getScheduleDates(
            getSelectedDoctor.value.id,
            getSelectedDoctor.value.speciality_id,
            selected_year.value,
            month_index,
            getSelectedDoctor.value,
            checkRescheduleFlag.value
        );
    } else {
        store.getScheduleDates(
            getSelectedDoctor.value.id,
            getSpecializationData.value.id,
            selected_year.value,
            month_index,
            getSelectedDoctor.value,
            is_reschedule.value
        );
    }
}
// Get Schedule List according to the year selected
function selectYear(year) {
    window.localStorage.setItem('getY', JSON.stringify(year))
    selected_year.value = year;
    selectedTab.value = 0;
    if (year > getYear) {
        restMonth.value = getMonths.value;
        if (
            getSelectedDoctor.value.speciality_id &&
            checkRescheduleFlag.value === true
        ) {
            store.getScheduleDates(
                getSelectedDoctor.value.id,
                getSelectedDoctor.value.speciality_id,
                year,
                first_month.value,
                getSelectedDoctor.value,
                checkRescheduleFlag.value
            );
        } else {
            store.getScheduleDates(
                getSelectedDoctor.value.id,
                getSpecializationData.value.id,
                year,
                first_month.value,
                getSelectedDoctor.value,
                is_reschedule.value
            );
        }
    } else {
        let x = getMonths.value.filter((y) => {
            return y.ind > m;
        });
        restMonth.value = x;
        if (
            getSelectedDoctor.value.speciality_id &&
            checkRescheduleFlag.value === true
        ) {
            store.getScheduleDates(
                getSelectedDoctor.value.id,
                getSelectedDoctor.value.speciality_id,
                year,
                current_month.value,
                getSelectedDoctor.value,
                checkRescheduleFlag.value
            );
        } else {
            store.getScheduleDates(
                getSelectedDoctor.value.id,
                getSpecializationData.value.id,
                year,
                current_month.value,
                getSelectedDoctor.value,
                is_reschedule.value
            );
        }
    }
}

// Moment to change date 
function getTime(datetime) {
    return moment(datetime).format("HH:mm a");
}
function getDate(dates) {
    return moment(dates).format("ddd, MMM Do,  YYYY ");
}

// Book Appointment
function bookAppointment(datetime, id, dates, times, schedule_id) {
    appointment_date_time.value = datetime;
    time_slot_id.value = id;
    time = times;
    appointment_date.value = dates;
    appointment_id.value = id;
    store.getPaymentInfo(id);
}

// Reschedule Appointment
function rescheduleApp(date, time, id) {
    appointmentdate.value = date;
    appointmenttime.value = time;
    docname.value = getImmigrationDoctorDetail.value.doctor_name;
    target_schedule_id = id;
    let booking_body = {
        id: id,
    };
    store.getBookingIdData(booking_body);
}
</script>

<style scoped>
.time-slot {
    position: relative;
    border: none;
    border-radius: 5px;
    color: var(--foundation----primary---p300);
    padding: 10px;
    margin: 15px 10px;
    cursor: pointer;
    justify-content: center;
    display: flex;
    background-color: var(--foundation----primary---p75);
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    transition: transform 0.2s;
}

.time-slot:hover {
    color: var(--foundation---shades---s0);
    background-color: var(--foundation----primary---p200);
    transform: scale(1.05);
}

.year-section {
    width: 100%;
    top: 30px;
}
</style>
