<template>
    <!-- <div class="row"> -->
        <div class="col-sm-12">
            <div class="rounded shadow-sm">
                <div class="osahan-privacy bg-white rounded shadow-sm p-4">
  
                    <div class="row">
                        <div >
<router-link :to="{name: 'page.opdDept'}">
        <font-awesome-icon icon="fa-solid fa-arrow-left" style=" margin-right: 10px; font-size: 14px;" />
        <span class="m-none" style="font-size: 12px;" >Back</span>
</router-link>
</div>
                        <div class="col-12 col-md-8 col-9">
 
                        <div class=" card-primary-outline mt-2 m-auto d-flex align-items-center" style="box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; justify-content: space-between;">


<div class="">
<span class=" ml-2 text-lg">{{ getFetchedDocsInSpecility.speciality?.name }}</span>
</div>
</div>
</div>
<div class="col-sm-2 mt-2" :class="getIsAuthorized ? 'col-sm-2' : 'col-md-4 col-xl-3'" style="position: relative; ">
                            
                            <input type="text" v-model="filter" class="form form-control"
                            style="font-size: 1.3rem;"
                                placeholder="Search Doctors" />
                            <font-awesome-icon class="searchIcon" icon="fa-solid fa-search" />
                        </div>
                    </div>

                  
                    
           
                <div class="row py-4" >
                    <h5> Doctors</h5>
                    <hr/>
                    <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>
                    <noRecordComp v-if="filteredDoctors?.length < 1 && !getLoaderStatus"
                        :title="'NO RECORD FOUND'">
                    </noRecordComp>
                
                    <div v-if="!getLoaderStatus" class="row department-list">
                        <div class="col-12 col-md-4 col-xl-3" v-for="(doc, index) in filteredDoctors" :key="index">
                            <div class="packages"
                                @click="fetchDoctors(doc)">
                                <div style="width: 60px; height: 60px; border-radius: 4px;">
                                    <img  :src="doc.image" style=" object-fit: contain; height: 60px; width: 100%; "/>
                                </div>
                                <div class="package_name">
                                    <span class="fo-text-color-primary-p500 fw-bold d-flex justify-content-center align-items-center">{{
                                        doc.doctor_name
                                    }}</span>
                                </div>
                                <div class="right-icon">
                                    <font-awesome-icon class="fo-text-color-primary-p500" icon="fa-solid fa-arrow-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, computed } from "vue";
import { storeToRefs } from "pinia";
import SpinnerDataLoader from "@/components/loaders/spinnerloader";
import noRecordComp from "@/components/norecord";
import { useSelfAppointment } from '@/core/modules/self/appointment';
import {useStoreAppointment} from "@/core/modules/appointment/appointment"
import {useManageUser} from '@/core/modules/auth/user'
import { useRoute } from "vue-router";

const { getIsAuthorized}  =  storeToRefs(useManageUser())
const store = useSelfAppointment();
const storeAppointment=useStoreAppointment()
const dept_id = ref(null)

const {getLoaderStatus,getFetchedDocsInSpecility}=storeToRefs(useStoreAppointment())
const filter = ref("");

const router = useRouter();
const route  =  useRoute()
onMounted(async () => {
    await storeAppointment.fetchDoctorsInSpecility(route.params.id)
})

function fetchDoctors(body) {
    if(body){
        // go to doc route

        router.push({name: "page.docSlot",params:{
        id:body.id,
        // country_id:this.$route.params.country_id,
       }})
    
    }
    
  
}



const filteredDoctors = computed(() => {
    let filteredData = getFetchedDocsInSpecility.value?.doctors;
    if (filteredData)
        return getFetchedDocsInSpecility.value?.doctors?.filter((doctor) => {
            return doctor.doctor_name
                .toLowerCase()
                .includes(filter.value.toLowerCase());
        });
    else return filteredData;
});
</script>

<style scoped>
.appointment-tabs {
    display: flex;
}
</style>
