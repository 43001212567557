<template>
    <modalComp :idmodal="'bookappointment'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Book Appointment</h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>
        <template v-slot:body>
            <div class="strongpassword">Are you sure you want to book an appointment for <span class="fw-bold">{{
                appointment_date_time }}</span> ?
            </div>
            <div v-if="speciality === 49 && reschedule_appointment === false">
                <div class="form-group mb-3" style="position: relative">
                    <label class="small fw-bold pb-1">HAP ID</label>
                    <input class="form-control mb-10" placeholder="Enter your HAP ID" v-model="hap_id">
                    <label class="small fw-bold pb-1">Passport Number</label>
                    <input class="form-control mb-10" placeholder="Enter your passport number" v-model="passport_no">
                    <label class="small fw-bold pb-1">Country Code</label>
                    <input type="number" class="form-control mb-10" placeholder="Enter your country code"
                        v-model="country_code">
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="col-6 m-0 p-0">
                <button type="button" class="btn border-top btn-lg w-100" data-bs-dismiss="modal"
                    @click="book_appointment('book')">Book</button>
            </div>
            <div class="col-6 m-0 p-0">
                <button type="button" data-bs-dismiss="modal" class="btn btn-primary btn-lg w-100" @click="book_appointment('pay')">Book and
                    Pay</button>
            </div>
        </template>
    </modalComp>
</template>

<script setup>
import modalComp from '@/components/common/modal'
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSelfAppointment } from "@/core/modules/self/appointment"
const store = useSelfAppointment()
const hap_id = ref('')
const passport_no = ref('')
const country_code = ref('')
const speciality = ref('')
const reschedule_flag = ref('')
const props = defineProps({
    appointment_date_time: String,
    time_slot: Number,
    dept_id: Number,
    speciality_id: Number,
    reschedule_appointment: Boolean,
    appointmentId: Number
})

onMounted(() => {
    speciality.value = props.speciality_id;
    reschedule_flag.value = props.reschedule_appointment
    let bookmodel = document.getElementById('bookappointment')
    bookmodel.addEventListener('hidden.bs.modal', function () {
        hap_id.value = "";
        passport_no.value = "",
            country_code.value = ""
    })
})
function book_appointment(bp) {
    var body = {
        id: props.time_slot,
        hap_id: hap_id.value,
        passport_no: passport_no.value,
        country_code: country_code.value
    }
}

</script>