import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";
// import { toast } from 'vue3-toastify'
// import { useJwtService } from "@/core/services/jwt";

export const useAuthResetPasswordRef = defineStore(
  "authresetpasswordref",
  () => {
    const phoneNumber = ref();
    return { phoneNumber };
  }
);

export const useAuthResetPassword = defineStore("authresetpassword", {
  state: () => ({
    errors: null,
    loaderStatus: false,
  }),
  getters: {
    getErrors: (state) => {
      return state.errors;
    },
    getLoaderStatus: (state) => {
      return state.loaderStatus;
    },
  },
  actions: {
    async submitReset(body) {
      try {
        this.setErrors(null)
        this.loaderStatus = true;
        await axios
          .post("/sendOtp", body)
          .then((response) => {
            this.loaderStatus = false;
            // eslint-disable-next-line no-debugger
            // debugger
            if (response.data.status == 200) {
              window.localStorage.setItem("phoneNumber", body.contact);
              this.$router.push("/otp");
            }
          })
          .catch((error) => {
            this.setErrors(error.response.data)
            this.loaderStatus = false;
          });
      } catch (error) {
        this.loaderStatus = false;
      }
    },
    setErrors(error) {
      this.errors = error;
      },
  },
});
