import axios from "axios";
import { defineStore } from "pinia";

import { useApiHeader } from "@/core/services/apiheader";
import { useJwtService } from "@/core/services/jwt";
export const useManageUser = defineStore("manageuser", {
  state: () => ({
    errors: null,
    user: {},
    isAuthorized: false,
  }),
  getters: {
    getErrors: (state) => {
      return state.errors;
    },
    getUser: (state) => {
      return state.user;
    },
    getIsAuthorized:(state) => state.isAuthorized
  },
  actions: {
    pugeAuth(){
      this.isAuthorized =  false
      this.user =  {}
      const jwtService = useJwtService();
      jwtService.destroyToken();
    },
    setUser(user){
      this.user =  user;
    },
    setIsAuthorized(isAuthorized){
      this.isAuthorized =  isAuthorized
    },
    async getUserDetails() {
   
      const apiHeader = useApiHeader();
      try {
        this.setErrors(null)
        this.loaderStatus = true;
        apiHeader.setHeader();
        await axios
          .get("/user_info")
          .then((response) => {
            this.loaderStatus = false;
            if (response.data.status == 200) {
              // sessionStorage.setItem('user_session_key',Math.random());
              var user = response.data.data.user_info;
              this.user = user;
              this.isAuthorized = true;
              
            }
          })
          .catch((error) => {
            this.setErrors(error.response.data)
            this.loaderStatus = false;
            this.isAuthorized = false;
          });
      } catch (error) {
        this.loaderStatus = false;
      }
    },
    setErrors(error) {
      this.errors = error;
      },
  },
});
